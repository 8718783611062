"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_redux_1 = require("react-redux");
const native_base_1 = require("native-base");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const user_1 = require("../../store/selectors/user");
const ChatThreadsSheet_1 = require("./ChatThreadsSheet");
const colors_1 = require("../../common/colors");
const ChatThreadsHeaderButton = ({ placement = 'bottom right', menuHorizontalOffset, menuVerticalOffset }) => {
    const character = (0, react_redux_1.useSelector)(user_1.selectCharacterProfile);
    const [menuOpen, setMenuOpen] = (0, react_1.useState)(false);
    // const {
    //   isOpen: isOpenChatThreads,
    //   onOpen: onOpenChatThreads,
    //   onClose: onCloseChatStyle,
    // } = useDisclose();
    // const ButtonOpenThread = useCallback(() => (
    //   <IconButton
    //     onPress={() => {
    //       onOpenChatThreads();
    //     }}
    //     _pressed={{
    //       opacity: 0.7,
    //       background: "transparent",
    //     }}
    //     size="lg"
    //     height={30}
    //     width={30}
    //     alignItems={"center"}
    //     justifyItems={"center"}
    //   >
    //     <FontAwesomeIcon icon={faInboxFull} color={Colors.white} size={22} />
    //   </IconButton>
    // ), [onOpenChatThreads]);
    const openMenu = (0, react_1.useCallback)(() => setMenuOpen(true), []);
    const closeMenu = (0, react_1.useCallback)(() => setMenuOpen(false), []);
    // const MenuOrModel = memo(() => {
    //   if (isTabOrDesktopDevice) return <MenuBox />;
    //   return <ButtonOpenThread />;
    // });
    return ((0, jsx_runtime_1.jsx)(native_base_1.Menu, { isOpen: menuOpen, onOpen: openMenu, onClose: closeMenu, closeOnSelect: true, style: styles.menu, placement: placement, offset: menuVerticalOffset, crossOffset: menuHorizontalOffset, trigger: (triggerProps) => ((0, jsx_runtime_1.jsx)(native_base_1.Pressable, { style: styles.button, accessibilityLabel: "Open menu", ...triggerProps, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faInboxFull, color: colors_1.Colors.white, size: 22 }) })), children: (0, jsx_runtime_1.jsx)(native_base_1.VStack, { paddingY: 4, minHeight: 200, children: (0, jsx_runtime_1.jsx)(ChatThreadsSheet_1.ChatThreadsContent, { character: character, onClose: closeMenu }) }) }));
    // return (
    //   <>
    //     <MenuOrModel />
    //     {isOpenChatThreads && (
    //       <ChatThreadsSheet
    //         isOpen={isOpenChatThreads}
    //         onOpen={onOpenChatThreads}
    //         onClose={onCloseChatStyle}
    //       />
    //     )}
    //   </>
    // );
};
const styles = react_native_1.StyleSheet.create({
    menu: {
        backgroundColor: colors_1.Colors.DARK_600,
        width: 400,
        paddingVertical: 12,
        paddingTop: 0,
        borderRadius: 12,
        shadowColor: colors_1.Colors.DARK_700,
        shadowRadius: 10,
        shadowOpacity: 1,
    },
    button: {
        height: 30,
        width: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
});
exports.default = ChatThreadsHeaderButton;
