"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ActionButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const useDevice_1 = require("../../../hooks/useDevice");
const AppText_1 = require("../../../component/AppText");
const colors_1 = require("../../../common/colors");
;
function ActionButton({ name, icon, isCompactSize, style, ...restProps }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { style: (pressed) => [styles.container, typeof style === 'function' ? style(pressed) : style], ...restProps, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.iconContainer, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: icon, color: colors_1.Colors.CTA_CTA, size: 14 }) }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [styles.text, (isMobileScreen || isCompactSize) && styles.textMobile], children: name })] }));
}
;
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: 'rgba(243, 242, 251, 0.08)',
        gap: 8,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 6,
        paddingHorizontal: 8,
        borderRadius: 8,
    },
    iconContainer: {
        padding: 1,
    },
    text: {
        fontSize: 12,
        color: 'rgba(243, 242, 251, 0.64)',
        lineHeight: 16,
    },
    textMobile: {
        fontSize: 14,
    }
});
