"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = AppTooltip;
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const colors_1 = require("../../common/colors");
function AppTooltip(props) {
    return ((0, jsx_runtime_1.jsx)(native_base_1.Tooltip, { placement: 'bottom left', offset: 8, backgroundColor: colors_1.Colors.DARK_600, paddingX: 12, paddingY: 8, borderWidth: 1, borderColor: colors_1.Colors.DARK_500, borderRadius: 8, _text: {
            color: colors_1.Colors.white,
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 18
        }, ...props }));
}
;
