"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeUserToken = decodeUserToken;
function decodeUserToken(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    }
    catch {
        return null;
    }
    ;
}
;
