"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = NotesHeaderIcon;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const colors_1 = require("../../../common/colors");
function NotesHeaderIcon() {
    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.container, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faNotes, color: colors_1.Colors.NEUTRAL_00, size: 20 }) }));
}
;
const styles = react_native_1.StyleSheet.create({
    container: {
        padding: 2,
    },
});
