"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = SourceButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
const colors_1 = require("../../common/colors");
function SourceButton({ sourcePath, webUrl, youtubeUrl }) {
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    const displayPath = (0, react_1.useMemo)(() => youtubeUrl || webUrl || sourcePath, [youtubeUrl, webUrl, sourcePath]);
    const handlePress = (0, react_1.useCallback)(() => {
        react_native_1.Linking.openURL(displayPath);
    }, [displayPath]);
    const buttonStyle = (0, react_1.useMemo)(() => ([styles.button, isHovered && styles.buttonHovered]), [isHovered]);
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: handlePress, onHoverIn: () => setIsHovered(true), onHoverOut: () => setIsHovered(false), style: buttonStyle, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.iconContainer, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { style: styles.icon, color: isHovered ? colors_1.Colors.PRIMARY_300 : colors_1.Colors.white, icon: pro_regular_svg_icons_1.faArrowUpRightFromSquare }) }) }));
}
;
const styles = react_native_1.StyleSheet.create({
    button: {
        backgroundColor: colors_1.Colors.DARK_500,
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
    },
    buttonHovered: {
        backgroundColor: colors_1.Colors.DARK_700,
        borderWidth: 1,
        borderColor: colors_1.Colors.PRIMARY_300,
    },
    iconContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
    // Remove marginLeft as it's no longer needed
    }
});
