"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = SoundBiteButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const AppText_1 = require("../AppText");
const spacing_1 = require("../../common/spacing");
const colors_1 = require("../../common/colors");
const constants_1 = require("../../common/constants");
const react_redux_1 = require("react-redux");
const READY_TO_PLAY_TIMEOUT = 3000;
function SoundBiteButton({ isLoading, fullWidth, style, showText = true, ...restProps }) {
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    const readyControlRef = (0, react_1.useRef)(true);
    const [readyToPlay, setIsReadyToPlay] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        let readyToPlayTimeout;
        if (isLoading) {
            readyControlRef.current = false;
        }
        else if (!readyControlRef.current) {
            readyControlRef.current = true;
            setIsReadyToPlay(true);
            readyToPlayTimeout = setTimeout(() => {
                setIsReadyToPlay(false);
            }, READY_TO_PLAY_TIMEOUT);
        }
        ;
        return () => clearTimeout(readyToPlayTimeout);
    }, [isLoading]);
    const { selectedFontId, selectedVariant } = (0, react_redux_1.useSelector)((state) => state.fontSettings);
    const selectedFont = constants_1.AVAILABLE_FONTS.find(font => font.id === selectedFontId);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { onHoverIn: () => setIsHovered(true), onHoverOut: () => setIsHovered(false), disabled: isLoading, style: typeof style === 'function'
            ? (pressed) => [
                styles.button,
                readyToPlay && styles.readyToPlay,
                style(pressed),
                fullWidth && { flex: 1 },
                isHovered && styles.buttonHovered
            ]
            : [
                styles.button,
                readyToPlay && styles.readyToPlay,
                style,
                fullWidth && { flex: 1 },
                isHovered && styles.buttonHovered
            ], ...restProps, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.iconWrapper, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { alignItems: 'center', justifyContent: 'center' }, children: isLoading ? ((0, jsx_runtime_1.jsx)(react_native_1.ActivityIndicator, { color: colors_1.Colors.PRIMARY_300, size: 16 })) : ((0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHeadphones, color: colors_1.Colors.PRIMARY_300, size: 16 })) }) }), showText && selectedFont?.id !== 'opendyslexic' &&
                (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.text, children: isLoading ? 'Generating...' : readyToPlay ? 'Ready to Play' : 'SoundBite' })] }));
}
;
const styles = react_native_1.StyleSheet.create({
    button: {
        backgroundColor: '#25252A',
        gap: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: spacing_1.Spacing.SPACING_MD,
        paddingHorizontal: spacing_1.Spacing.SPACING_LG,
        borderColor: 'rgba(36, 36, 39, 0.16)',
        borderWidth: 1,
        borderRadius: 38,
    },
    readyToPlay: {
        backgroundColor: 'rgba(116, 201, 227, 0.30)',
        borderColor: 'rgba(116, 201, 227, 0.16)',
    },
    buttonHovered: {
        backgroundColor: '#2F2F35',
    },
    iconWrapper: {
        width: 20,
        height: 20,
        alignContent: 'center',
        justifyContent: 'center',
    },
    text: {
        color: 'white',
        flex: 1,
    },
});
