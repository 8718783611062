"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.startDocumentsListeners = startDocumentsListeners;
const document_1 = require("../../slices/document");
const _1 = require(".");
function startDocumentsListeners() {
    (0, _1.startListening)({
        predicate(_action, currentState, originalState) {
            return currentState.getUserOptions.appUser?.id !== originalState.getUserOptions.appUser?.id;
        },
        effect(_action, listenerAPI) {
            listenerAPI.dispatch((0, document_1.resetDocuments)());
        },
    });
}
