"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFontVariant = exports.setSelectedFont = exports.selectFontFamily = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../common/constants");
const initialState = {
    selectedFontId: 'inter',
    selectedVariant: 'regular'
};
const fontSettingsSlice = (0, toolkit_1.createSlice)({
    name: 'fontSettings',
    initialState,
    reducers: {
        setSelectedFont: (state, action) => {
            const fontExists = constants_1.AVAILABLE_FONTS.some(font => font.id === action.payload);
            if (fontExists) {
                const selectedFont = constants_1.AVAILABLE_FONTS.find(font => font.id === action.payload);
                state.selectedFontId = action.payload;
                state.selectedVariant = selectedFont?.variants?.regular || 'regular';
            }
        },
        setFontVariant: (state, action) => {
            const selectedFont = constants_1.AVAILABLE_FONTS.find(font => font.id === state.selectedFontId);
            if (selectedFont && selectedFont.variants[action.payload]) {
                state.selectedVariant = action.payload;
            }
        }
    }
});
const selectFontFamily = (state) => constants_1.AVAILABLE_FONTS.find((font) => (state.fontSettings.selectedFontId === font.id));
exports.selectFontFamily = selectFontFamily;
_a = fontSettingsSlice.actions, exports.setSelectedFont = _a.setSelectedFont, exports.setFontVariant = _a.setFontVariant;
exports.default = fontSettingsSlice.reducer;
