"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signOut = exports.watchAuthState = exports.getRefreshedUserToken = exports.getUserToken = exports.getSignedUser = exports.signInPopUp = exports.signInRedirect = exports.googleProvider = exports.appleProvider = void 0;
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
const auth_2 = require("firebase/auth");
const application_host_1 = require("../common/application-host");
const firebaseConfig = application_host_1.DEV_MODE ?
    {
        apiKey: "AIzaSyAfv38vZRuiN234mKdjT29cfGd6OziYHsc",
        authDomain: "dev-auth-makrai.firebaseapp.com",
        projectId: "dev-auth-makrai",
        storageBucket: "dev-auth-makrai.appspot.com",
        messagingSenderId: "58734607019",
        appId: "1:58734607019:web:1d800b81647761723555ee",
        measurementId: "G-LJ2WFDP4VM",
    }
    : {
        apiKey: "AIzaSyApZvGf3dCW8qH1ANYdOLEqIdDaJlWcZvQ",
        authDomain: "auth-makrai.firebaseapp.com",
        projectId: "auth-makrai",
        storageBucket: "auth-makrai.appspot.com",
        messagingSenderId: "956157246644",
        appId: "1:956157246644:web:832fadc9cdfaf4bb93abe1",
        measurementId: "G-7F6FK3F4ZX",
    };
(0, app_1.initializeApp)(firebaseConfig);
const auth = (0, auth_1.getAuth)();
exports.appleProvider = new auth_1.OAuthProvider('apple.com');
exports.googleProvider = new auth_2.GoogleAuthProvider();
exports.googleProvider.setCustomParameters({
    prompt: 'select_account',
});
const signInRedirect = async (provider) => {
    await (0, auth_1.signInWithRedirect)(auth, provider);
    return (0, auth_1.getRedirectResult)(auth).then((result) => {
        const credential = result && auth_1.OAuthProvider.credentialFromResult(result);
        // Notify iOS WebView about successful login
        if (window.webkit?.messageHandlers?.watchauth) {
            window.webkit.messageHandlers.watchauth.postMessage("watchauth");
        }
        return ({
            credential,
            token: credential?.accessToken,
            user: {
                ...result?.user,
                additionalInformation: result && (0, auth_1.getAdditionalUserInfo)(result),
            }
        });
    }).catch((error) => ({
        errorCode: error.code,
        errorMessage: error.message,
        errorEmail: error.customData.email,
        errorCredential: auth_1.OAuthProvider.credentialFromError(error),
    }));
};
exports.signInRedirect = signInRedirect;
const signInPopUp = async (provider) => (await (0, auth_1.signInWithPopup)(auth, provider).then((result) => {
    const credential = auth_1.OAuthProvider.credentialFromResult(result);
    // Notify iOS WebView about successful login
    if (window.webkit?.messageHandlers?.watchauth) {
        window.webkit.messageHandlers.watchauth.postMessage("watchauth");
    }
    return ({
        credential,
        token: credential?.accessToken,
        user: {
            ...result?.user,
            additionalInformation: (0, auth_1.getAdditionalUserInfo)(result),
        }
    });
}).catch((error) => ({
    errorCode: error.code,
    errorMessage: error.message,
    errorEmail: error.customData.email,
    errorCredential: auth_1.OAuthProvider.credentialFromError(error),
})));
exports.signInPopUp = signInPopUp;
const getSignedUser = () => auth.currentUser;
exports.getSignedUser = getSignedUser;
const getUserToken = () => (0, exports.getSignedUser)()?.getIdToken();
exports.getUserToken = getUserToken;
const getRefreshedUserToken = () => (0, exports.getSignedUser)()?.getIdToken(true);
exports.getRefreshedUserToken = getRefreshedUserToken;
exports.watchAuthState = ((handlerCallback) => {
    // Notify iOS WebView that we're starting auth watching
    if (window.webkit?.messageHandlers?.watchauth) {
        window.webkit.messageHandlers.watchauth.postMessage("watchauth");
    }
    return (0, auth_1.onAuthStateChanged)(auth, (user) => {
        // Call the provided callback
        handlerCallback(user);
        // Notify iOS WebView about auth state changes
        if (window.webkit?.messageHandlers?.authStateChanged) {
            window.webkit.messageHandlers.authStateChanged.postMessage({
                isAuthenticated: !!user,
                userId: user?.uid || null
            });
        }
    });
});
const signOut = () => (auth.signOut().then(() => true).catch(() => false));
exports.signOut = signOut;
exports.default = auth;
