"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ProfileIcon;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const react_redux_1 = require("react-redux");
const useDevice_1 = require("../../hooks/useDevice");
const rootNavigation_1 = require("../../navigation/rootNavigation");
const user_1 = require("../../store/selectors/user");
const colors_1 = require("../../common/colors");
const AppText_1 = require("../AppText");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
function ProfileIcon(props) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    const userName = (0, react_redux_1.useSelector)(user_1.selectDisplayName);
    const fbUserId = (0, react_redux_1.useSelector)(user_1.selectfbUserId);
    const [firstName, lastName] = userName
        ? userName.split(' ')
        : ['', ''];
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => (0, rootNavigation_1.navigate)('Account'), ...props, children: (0, jsx_runtime_1.jsx)(native_base_1.Avatar, { size: isMobileScreen ? 7 : 10, bg: colors_1.Colors.PRIMARY_100, children: fbUserId ? ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.PRIMARY_700, fontSize: isMobileScreen ? 12 : 20, fontWeight: 500, children: `${firstName?.[0] || ''}${lastName?.[0] || ''}` })) : ((0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_regular_svg_icons_1.faUser, color: colors_1.Colors.PRIMARY_700, size: isMobileScreen ? 12 : 20 })) }) }));
}
