"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_gesture_handler_1 = require("react-native-gesture-handler");
const react_native_reanimated_1 = __importStar(require("react-native-reanimated"));
const SCROLL_BAR_DEFAULT_COLOR = "#ABABAB";
const SCROLL_BAR_DEFAULT_WIDTH = 5;
const SCROLL_BAR_DEFAULT_DISTANCE = 16.5;
const SCROLLBAR_HIDE_TIMEOUT = 5000;
const SCROLLBAR_ANIM_HIDE_TIME = 300;
const MIN_INDICATOR_LENGTH = 10;
const INDICATOR_HIDDEN_OPACITY = 0.1;
const INDICATOR_VISIBLE_OPACITY = 1;
const scrollTimingAnimConfig = {
    duration: SCROLLBAR_ANIM_HIDE_TIME,
    easing: react_native_reanimated_1.Easing.linear,
};
const transformDimension = (dimension) => (`${typeof dimension === 'number' ? `${dimension}px` : dimension}`);
function CustomScrollView(props, ref) {
    const { customIndicator, horizontal, showsHorizontalScrollIndicator, showsVerticalScrollIndicator, indicatorColor = SCROLL_BAR_DEFAULT_COLOR, indicatorWidth: indicatorLength = SCROLL_BAR_DEFAULT_WIDTH, indicatorDistance = SCROLL_BAR_DEFAULT_DISTANCE, indicatorOverlay, containerStyle, onLayout: customOnLayout, onContentSizeChange: customOnContentSizeChange, children, ...propsRest } = props;
    if (!customIndicator) {
        return (0, jsx_runtime_1.jsx)(react_native_gesture_handler_1.ScrollView, { ref: ref, ...props });
    }
    (0, react_1.useImperativeHandle)(ref, () => (scrollViewReference.current ?? undefined));
    const [scrollSize, setScrollSize] = (0, react_1.useState)(0);
    const [contentSize, setContentSize] = (0, react_1.useState)(0);
    const [scrollPosition, setScrollPosition] = (0, react_1.useState)(0);
    const [maxIndicatorOffset, setMaxIndicatorOffset] = (0, react_1.useState)(0);
    const indicatorSize = (0, react_native_reanimated_1.useSharedValue)(0);
    const indicatorOffset = (0, react_native_reanimated_1.useSharedValue)(0);
    const indicatorOpacity = (0, react_native_reanimated_1.useSharedValue)(INDICATOR_HIDDEN_OPACITY);
    const scrollStartPos = (0, react_1.useRef)(0);
    const indicatorTimeoutId = (0, react_1.useRef)();
    const scrollViewReference = (0, react_1.useRef)(null);
    const isScrollVertical = (0, react_1.useMemo)(() => !horizontal, [horizontal]);
    const isScrollHorizontal = (0, react_1.useMemo)(() => horizontal, [horizontal]);
    const hasScrolling = (0, react_1.useMemo)(() => (contentSize > scrollSize && (isScrollVertical && showsVerticalScrollIndicator !== false
        || isScrollHorizontal && showsHorizontalScrollIndicator !== false)), [
        isScrollVertical,
        isScrollHorizontal,
        showsVerticalScrollIndicator,
        showsHorizontalScrollIndicator,
        scrollSize,
        contentSize,
    ]);
    const indicatorDistanceValue = (0, react_1.useMemo)(() => `calc(${transformDimension(indicatorLength)} + ${transformDimension(indicatorDistance)})`, [indicatorLength, indicatorDistance]);
    const calculateIndicatorSize = (0, react_1.useCallback)(() => {
        if (hasScrolling) {
            return (scrollSize / contentSize) * scrollSize;
        }
        else {
            return 0;
        }
        ;
    }, [hasScrolling, scrollSize, contentSize]);
    const calculateIndicatorPosition = (0, react_1.useCallback)((scrollPosition) => {
        const scrollPercentage = Math.min(Math.max(scrollPosition / (contentSize - scrollSize), 0), 1);
        return maxIndicatorOffset * scrollPercentage;
    }, [contentSize, scrollSize, maxIndicatorOffset]);
    const createIndicatorHideTimeout = (0, react_1.useCallback)(() => (setTimeout(() => {
        indicatorOpacity.value = INDICATOR_HIDDEN_OPACITY;
    }, SCROLLBAR_HIDE_TIMEOUT)), [indicatorOpacity]);
    const resetHideTimeout = (0, react_1.useCallback)(() => {
        indicatorOpacity.value = INDICATOR_VISIBLE_OPACITY;
        clearTimeout(indicatorTimeoutId.current);
        indicatorTimeoutId.current = createIndicatorHideTimeout();
    }, [indicatorOpacity, createIndicatorHideTimeout]);
    const setScrollPositionStart = (0, react_1.useCallback)(() => {
        scrollStartPos.current = scrollPosition;
    }, [scrollPosition]);
    const scroll = (0, react_1.useCallback)((translation) => {
        const scrollPosition = (scrollStartPos.current + (contentSize - scrollSize) * (translation / maxIndicatorOffset));
        // This fix the scrolling hang on Android
        setTimeout(() => {
            scrollViewReference.current?.scrollTo({
                y: isScrollVertical ? scrollPosition : undefined,
                x: isScrollHorizontal ? scrollPosition : undefined,
                animated: false,
            });
        }, 10);
    }, [scrollSize, contentSize, maxIndicatorOffset, isScrollVertical, isScrollHorizontal]);
    const indicatorDragGesture = (0, react_1.useMemo)(() => (react_native_gesture_handler_1.Gesture.Pan().onBegin(() => {
        (0, react_native_reanimated_1.runOnJS)(setScrollPositionStart)();
    })
        .onChange((panEvent) => {
        (0, react_native_reanimated_1.runOnJS)(scroll)(isScrollVertical ? panEvent.translationY : panEvent.translationX);
    })), [setScrollPositionStart, scroll, isScrollVertical]);
    (0, react_1.useEffect)(() => {
        indicatorTimeoutId.current = createIndicatorHideTimeout();
        return () => {
            clearTimeout(indicatorTimeoutId.current);
        };
    }, [createIndicatorHideTimeout]);
    (0, react_1.useEffect)(() => {
        indicatorSize.value = calculateIndicatorSize();
        setMaxIndicatorOffset(scrollSize - indicatorSize.value);
    }, [indicatorSize, calculateIndicatorSize, scrollSize]);
    const handleScroll = (0, react_1.useCallback)((scrollPosition) => {
        setScrollPosition(scrollPosition);
        indicatorOffset.value = calculateIndicatorPosition(scrollPosition);
        resetHideTimeout();
    }, [indicatorOffset, calculateIndicatorPosition, resetHideTimeout]);
    const indicatorPaddingStyle = (0, react_native_reanimated_1.useAnimatedStyle)(() => ({
        height: isScrollVertical ? indicatorSize.value : undefined,
        width: isScrollHorizontal ? indicatorSize.value : undefined,
        top: isScrollVertical ? indicatorOffset.value : undefined,
        left: isScrollHorizontal ? indicatorOffset.value : undefined,
    }), [
        indicatorSize,
        indicatorOffset,
        indicatorLength,
        isScrollVertical,
        isScrollHorizontal,
    ]);
    const indicatorStyle = (0, react_native_reanimated_1.useAnimatedStyle)(() => ({
        height: isScrollVertical ? indicatorSize.value : indicatorLength,
        width: isScrollHorizontal ? indicatorSize.value : indicatorLength,
        opacity: (0, react_native_reanimated_1.withTiming)(indicatorOpacity.value, scrollTimingAnimConfig),
    }), [
        indicatorSize,
        indicatorOpacity,
        indicatorLength,
        isScrollVertical,
        isScrollHorizontal,
    ]);
    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { onLayout: customOnLayout, style: containerStyle ?? styles.containerDefault, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.scrollWrapper, !indicatorOverlay && {
                    paddingRight: hasScrolling && isScrollVertical ? indicatorDistanceValue : undefined,
                    paddingBottom: hasScrolling && isScrollHorizontal ? indicatorDistanceValue : undefined,
                }], children: [(0, jsx_runtime_1.jsx)(react_native_gesture_handler_1.GestureDetector, { gesture: indicatorDragGesture, children: (0, jsx_runtime_1.jsx)(react_native_reanimated_1.default.View, { style: [indicatorPaddingStyle, {
                                position: 'absolute',
                                right: isScrollVertical ? 0 : undefined,
                                bottom: isScrollHorizontal ? 0 : undefined,
                                zIndex: 1,
                                minWidth: isScrollVertical ? MIN_INDICATOR_LENGTH : undefined,
                                minHeight: isScrollHorizontal ? MIN_INDICATOR_LENGTH : undefined,
                                alignItems: isScrollVertical ? 'center' : undefined,
                                justifyContent: isScrollHorizontal ? 'center' : undefined,
                            }], children: (0, jsx_runtime_1.jsx)(react_native_reanimated_1.default.View, { style: [indicatorStyle, {
                                    backgroundColor: indicatorColor,
                                }, styles.indicatorStatic] }) }) }), (0, jsx_runtime_1.jsx)(react_native_gesture_handler_1.ScrollView, { ...propsRest, ref: scrollViewReference, horizontal: isScrollHorizontal, showsVerticalScrollIndicator: isScrollVertical ? false : undefined, showsHorizontalScrollIndicator: isScrollHorizontal ? false : undefined, onLayout: ({ nativeEvent: { layout: { height, width } } }) => {
                        setScrollSize(isScrollVertical ? height : width);
                    }, onContentSizeChange: (contentWidth, contentHeight) => {
                        customOnContentSizeChange?.(contentWidth, contentHeight);
                        setContentSize(isScrollVertical ? contentHeight : contentWidth);
                    }, onScroll: ({ nativeEvent: { contentOffset } }) => {
                        handleScroll(isScrollVertical ? contentOffset.y : contentOffset.x);
                    }, scrollEventThrottle: 10, bounces: false, overScrollMode: "never", userSelect: "auto", children: children })] }) }));
}
const styles = react_native_1.StyleSheet.create({
    containerDefault: {
        flex: 1,
    },
    scrollWrapper: {
        flex: 1,
    },
    indicatorStatic: {
        zIndex: 10,
        borderRadius: 8,
    },
});
exports.default = (0, react_1.forwardRef)(CustomScrollView);
