"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ChevronBackButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
const rootNavigation_1 = require("./rootNavigation");
function ChevronBackButton({ style, arrowBackIcon, iconSize, backRoute, ...restProps }) {
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: backRoute
            ? () => Array.isArray(backRoute) ? (0, rootNavigation_1.navigate)(...backRoute) : (0, rootNavigation_1.navigate)(backRoute)
            : rootNavigation_1.goBack, style: [styles.button, style], ...restProps, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: arrowBackIcon ? pro_regular_svg_icons_1.faArrowLeft : pro_regular_svg_icons_1.faChevronLeft, size: iconSize || 24, color: "white" }) }));
}
;
const styles = react_native_1.StyleSheet.create({
    button: {
        justifyContent: 'center',
        padding: 4,
    },
});
