"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = NavSidebarTabs;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const react_native_1 = require("react-native");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const native_1 = require("@react-navigation/native");
const native_base_1 = require("native-base");
const react_1 = require("react");
const react_native_safe_area_context_1 = require("react-native-safe-area-context");
const colors_1 = require("../common/colors");
const AppText_1 = require("../component/AppText");
const AppIcons_1 = require("../component/AppIcons");
const theme_1 = require("../helper/theme");
const useDevice_1 = require("../hooks/useDevice");
const rootNavigation_1 = require("./rootNavigation");
const ALL_TABS = {
    Chats: "Chats",
    Documents: 'Documents',
    Library: 'Library',
    Summary: 'Summary',
    Account: "Account",
};
const TAB_NAVIGATOR_HEIGHT = 100;
function NavSidebarTabs() {
    const navigation = (0, native_1.useNavigation)();
    const [activeTab, setActiveTab] = (0, react_1.useState)("Documents");
    const { deviceHeight } = (0, useDevice_1.useDevice)();
    const { bottom } = (0, react_native_safe_area_context_1.useSafeAreaInsets)();
    (0, react_1.useEffect)(() => {
        const unsubscribe = navigation.addListener("state", (e) => {
            const routeName = navigation.getCurrentRoute()?.name;
            if (routeName && ALL_TABS?.[routeName]) {
                console.log("navigation state:", routeName);
                setActiveTab(routeName);
            }
        });
        return unsubscribe;
    }, [navigation]);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: {
            width: 81,
            backgroundColor: "#121218",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: 8,
            borderColor: `${colors_1.Colors.PRIMARY_300}1A`,
        }, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: {
                    height: TAB_NAVIGATOR_HEIGHT,
                    justifyContent: 'center',
                    alignItems: 'center',
                }, children: (0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => {
                        setActiveTab("Documents");
                        (0, rootNavigation_1.navigate)("Home", { screen: "Documents" });
                    }, style: {
                        justifyContent: 'center',
                        alignItems: 'center',
                    }, children: (0, jsx_runtime_1.jsx)(AppIcons_1.LogoIcon, { width: 44, height: 55 }) }) }), (0, jsx_runtime_1.jsx)(native_base_1.VStack, { style: {
                    justifyContent: 'space-between',
                }, flex: 1, paddingY: "19", children: (0, jsx_runtime_1.jsxs)(native_base_1.VStack, { height: react_native_1.Platform.OS === 'web' ? 'auto' : (deviceHeight * 90) / 100 - bottom, space: 6, children: [(0, jsx_runtime_1.jsx)(TabButton, { title: "Docs", isActive: activeTab === "Documents" || activeTab === "Summary", icon: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faBookOpenCover, color: activeTab === "Documents" || activeTab === "Summary"
                                    ? colors_1.Colors.WHITE_SECONDARY
                                    : activeTab === "Discover"
                                        ? '#4E498F'
                                        : colors_1.Colors.DARK_200, size: 30 }), onPress: async () => {
                                (0, rootNavigation_1.navigate)("Home", { screen: "Documents" });
                                setActiveTab("Documents");
                            } }), (0, jsx_runtime_1.jsx)(TabButton, { title: "Chats", isActive: activeTab === "Chats", icon: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faComments, color: activeTab === "Chats"
                                    ? colors_1.Colors.WHITE_SECONDARY
                                    : activeTab === "Discover"
                                        ? '#4E498F'
                                        : colors_1.Colors.DARK_200, size: 32 }), onPress: () => {
                                setActiveTab("Chats");
                                (0, rootNavigation_1.navigate)("Home", { screen: "Chats" });
                            } }), (0, jsx_runtime_1.jsx)(TabButton, { title: "Library", isActive: activeTab === "Library", icon: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faBooks, color: activeTab === "Library"
                                    ? colors_1.Colors.WHITE_SECONDARY
                                    : activeTab === "Discover"
                                        ? '#4E498F'
                                        : colors_1.Colors.DARK_200, size: 30 }), onPress: async () => {
                                (0, rootNavigation_1.navigate)("Home", { screen: "Library" });
                                setActiveTab("Library");
                            } })] }) })] }));
}
const TabButton = ({ title, isActive, icon, onPress, isLast, }) => {
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: onPress, onHoverIn: () => setIsHovered(true), onHoverOut: () => setIsHovered(false), style: {
            justifyContent: "center",
            opacity: isHovered ? 0.8 : 1,
            ...(isLast && {
                justifyContent: "flex-end",
                alignContent: "flex-end",
                paddingHorizontal: react_native_1.Platform.OS === 'web' ? 15 : 'auto',
            }),
        }, children: (0, jsx_runtime_1.jsxs)(native_base_1.VStack, { space: react_native_1.Platform.OS === 'web' ? 0 : 1, alignContent: "center", alignItems: "center", fontSize: 24, children: [(0, jsx_runtime_1.jsx)(AppText_1.AppText, { height: 8, display: 'flex', alignItems: 'center', children: icon }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: isActive ? colors_1.Colors.WHITE_SECONDARY : colors_1.Colors.DARK_200, fontSize: (0, theme_1.normalize)(12), fontWeight: 500, lineHeight: 32, children: title })] }) }));
};
