"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useAnalytics;
const react_1 = require("react");
const posthog_react_native_1 = require("posthog-react-native");
const AnalyticsProvider_1 = require("../providers/AnalyticsProvider");
function useAnalytics() {
    const posthog = (0, posthog_react_native_1.usePostHog)();
    const { documentGenerationTimerRef } = (0, AnalyticsProvider_1.useAnalyticsContext)();
    const trackUserSignUp = (0, react_1.useCallback)(() => {
        posthog.capture('user_signed_up');
    }, [posthog]);
    const trackUserSignIn = (0, react_1.useCallback)(() => {
        posthog.capture('user_signed_in');
    }, [posthog]);
    const trackUserSignOut = (0, react_1.useCallback)(() => {
        posthog.capture('user_signed_out');
    }, [posthog]);
    const trackDocumentGeneration = (0, react_1.useCallback)(() => {
        documentGenerationTimerRef.current = Date.now();
    }, []);
    const trackSharing = (0, react_1.useCallback)(() => {
        posthog.capture('document_shared');
    }, [posthog]);
    return {
        trackUserSignUp,
        trackUserSignIn,
        trackUserSignOut,
        trackDocumentGeneration,
        trackSharing,
    };
}
;
