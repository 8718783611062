"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnalyticsContext = void 0;
exports.default = AnalyticsProvider;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const posthog_react_native_1 = require("posthog-react-native");
const src_1 = require("posthog-react-native/lib/posthog-core/src");
const store_1 = require("../store");
const user_1 = require("../store/selectors/user");
const document_1 = require("../slices/document");
const useDevice_1 = require("../hooks/useDevice");
;
const context = (0, react_1.createContext)({
    documentGenerationTimerRef: { current: 0 },
});
const Provider = context.Provider;
const useAnalyticsContext = () => (0, react_1.useContext)(context);
exports.useAnalyticsContext = useAnalyticsContext;
function AnalyticsProvider({ children }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    const posthog = (0, posthog_react_native_1.usePostHog)();
    const appUser = (0, react_redux_1.useSelector)(user_1.selectUser);
    const fbUser = (0, react_redux_1.useSelector)(user_1.selectFbUser);
    const lastDocumentPath = (0, store_1.useAppSelector)((state) => (0, document_1.selectLastDocument)(state)?.character.jsonPath);
    const [wasGenerationCharacterCreated, setWasGenerationCharacterCreated] = (0, react_1.useState)(false);
    const documentGenerationTimerRef = (0, react_1.useRef)(0);
    (0, react_1.useEffect)(() => {
        if (fbUser) {
            posthog.identify(appUser?.username, {
                user_id: appUser?.id,
            });
        }
        else {
            posthog.reset([src_1.PostHogPersistedProperty.Props]);
        }
    }, [appUser, fbUser, posthog]);
    (0, react_1.useEffect)(() => {
        posthog.register({
            isMobileScreen,
        });
    }, [isMobileScreen, posthog]);
    (0, react_1.useEffect)(() => {
        if (wasGenerationCharacterCreated && lastDocumentPath) {
            posthog.capture('document_generated', {
                time_spent: Date.now() - documentGenerationTimerRef.current,
            });
            setWasGenerationCharacterCreated(false);
        }
        ;
    }, [lastDocumentPath, wasGenerationCharacterCreated, posthog]);
    (0, react_1.useEffect)(() => {
        if (lastDocumentPath === null) {
            setWasGenerationCharacterCreated(true);
        }
        ;
    }, [lastDocumentPath]);
    return ((0, jsx_runtime_1.jsx)(Provider, { value: {
            documentGenerationTimerRef,
        }, children: children }));
}
