"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ChatFile;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const AppText_1 = require("../AppText");
const AppIcons_1 = require("../AppIcons");
const utils_1 = require("../../helper/utils");
const colors_1 = require("../../common/colors");
function ChatFile({ name, source, thumbnail, onClose }) {
    const Icon = (0, react_1.useCallback)(() => {
        switch (true) {
            case name.endsWith('.csv'):
                return thumbnail ? (0, jsx_runtime_1.jsx)(AppIcons_1.CSVIcon, {}) : (0, jsx_runtime_1.jsx)(AppIcons_1.CSVIcon2, {});
            case name.endsWith('.txt'):
                return (0, jsx_runtime_1.jsx)(AppIcons_1.TXTIcon2, {});
            default:
                return thumbnail ? (0, jsx_runtime_1.jsx)(AppIcons_1.PDFIcon, {}) : (0, jsx_runtime_1.jsx)(AppIcons_1.PDFIcon2, {});
        }
    }, [name, thumbnail]);
    const handleOpenSource = (0, react_1.useCallback)(() => {
        react_native_1.Linking.openURL(source);
    }, [source]);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { onPress: handleOpenSource, style: [styles.container, thumbnail && styles.thumbnailContainer], children: [(0, jsx_runtime_1.jsx)(Icon, {}), (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.textContaner, children: [(0, jsx_runtime_1.jsx)(AppText_1.AppText, { numberOfLines: 1, style: styles.fileName, children: name }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.extension, children: (0, utils_1.getFileUrlExtension)(name)?.toUpperCase() })] }), onClose && ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: onClose, style: styles.closeButton, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes, color: '#121218', size: 16 }) }))] }));
}
;
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: colors_1.Colors.DARK_600,
        gap: 10,
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        padding: 7,
        paddingRight: 18,
        borderWidth: 1,
        borderColor: colors_1.Colors.DARK_500,
        borderRadius: 12,
        maxWidth: 428,
    },
    thumbnailContainer: {
        maxWidth: 176,
    },
    textContaner: {
        gap: 2,
        flex: 1,
    },
    fileName: {
        color: '#FFF',
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 19.2,
    },
    extension: {
        color: '#818386',
        fontSize: 12,
        fontWeight: '700',
        lineHeight: 16,
    },
    closeButton: {
        backgroundColor: '#FEFEFF',
        position: 'absolute',
        top: 6,
        right: 6,
        borderRadius: 30,
    },
});
