"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGptJourneyImage = void 0;
const react_1 = require("react");
const messeges_1 = require("../services/messeges");
const user_1 = require("../slices/user");
const react_redux_1 = require("react-redux");
const useGptJourneyImage = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    /**
     *
     */
    const generateJourneyImages = (0, react_1.useCallback)(async (selectedText, conId, messageId, regenerate) => {
        await dispatch((0, user_1.setImageLoading)(true));
        const imageApiDto = {
            conversationId: conId,
            stream: false,
            message: `${selectedText}`,
            negativePrompt: "", //for regeneration
            style: "Dreamlike", //for regeneration
            ...(messageId && regenerate
                ? { messageId }
                : { parentMessageId: messageId }),
        };
        try {
            console.log("ChatImagePrompt imageApiDto journey:", imageApiDto);
            await dispatch((0, user_1.setChatAction)("generate"));
            await (0, messeges_1.sendMessegeImages)(imageApiDto).then(async (json) => {
                if (json?.data) {
                    console.log("Generated Chat Image sets...", json?.data);
                    await dispatch((0, user_1.setImageLoading)(false));
                }
                else {
                    console.log("JSON error sendMessegeImages:", json);
                }
                await dispatch((0, user_1.setImageLoading)(false));
            });
        }
        catch (e) {
            console.log("generateJourneyImages", e);
        }
        await dispatch((0, user_1.setImageLoading)(false));
    }, []);
    return {
        generateJourneyImages,
    };
};
exports.useGptJourneyImage = useGptJourneyImage;
