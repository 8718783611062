"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PREMIUM_ANNUALLY = exports.PREMIUM_MONTHLY = exports.ENABLE_PREMIUM_DAILY_TEST = exports.IOS_CLIENT_ID = exports.FB_WEB_CLIENT_ID = exports.REVENUE_CAT_API_KEY = exports.NegativePrompt = exports.ADMIN_EMAILS = exports.SOCIAL_LOGIN_ENABLED = exports.TAB_CHARACTER_COLUMN_COUNT = exports.TAB_PORTRAIT_SIDEBAR_MAX_WIDTH = exports.DISCOVER_SIDE_TAB_WIDTH = exports.SIDEBAR_MAX_WIDTH = exports.BREAKPOINT_XS = exports.DEFAULT_SPEED_RATE = exports.OPEN_API_SPEECH_API = exports.OPEN_AI_VOICE_MODEL = exports.POSTHOG_API_KEY = exports.OPENAI_API_KEY = exports.WEB_SOCKET_TOKEN = exports.VOICE_SPEED_LIST = exports.DEFAULT_VOICE_SPEED_INDEX = exports.DEFAULT_VOICE = exports.VOICES_LIST = exports.AUTOPLAY_MESSAGE_INTRO_IMAGE = exports.AUTOPLAY_MESSAGE_SPEECH = exports.USE_APPEND_ALL_CATEGORY = exports.USE_CHARACTER_DEVICE_CACHE = exports.USE_CATEGORY_DEVICE_CACHE = exports.TUTOR_MODE_FEATURE_ENABLED = exports.PROMPTS_FEATURE_ENABLED = exports.RESET_FEATURE_ENABLED = exports.SIGN_IN_ENABLED = exports.CUSTOM_USER_CATEGORY_NAME = exports.IGNORE_NESTED_SCROLL_LOG = exports.SUBSCRIPTION_DATE_TIME_FORMAT = exports.WARNING_MESSAGES_QUOTA = exports.WARNING_CHARACTER_QUOTA = exports.MAX_CHARACTER_LIMIT = exports.MAX_DOC_IMAGE_REGENERATION_LIMIT = exports.MAX_IMAGE_GENERATION_LIMIT = exports.EMAIL_REG = exports.KNOWN_FOR_HELP_TEXT = exports.WELCOME_FIRST_TIME_TEXT = exports.PROMPT_ERROR_MSG = exports.ERROR_MSG = exports.MESSAGES_PER_PAGE = exports.CACHE_EXPIRATION_DURATION_SEC = exports.CACHE_EXPIRATION_DURATION = exports.VOICE_CACHE_DIRECTORY_PATH = void 0;
exports.AVAILABLE_FONTS = exports.OUT_OF_QUOTA_MESSAGES = exports.DOCUMENT_SIZE_LIMIT_EXCCEDED = exports.WRONG_IMAGE_FILE_TYPE_MESSAGE = exports.WRONG_DOCUMENT_FILE_TYPE_MESSAGE = exports.SESSION_OUT_MESSAGE = exports.NOT_FOUND_MESSAGE = exports.DEFAULT_ERROR_MESSAGE = exports.DOCUMENT_MAXIMUM_SIZE_MB = exports.CHARACTER_PROMPTS_PLACEHOLDER = exports.FAQS = exports.PREMIUMCARDBULLETSTEXTS = exports.FREECARDBULLETSTEXTS = exports.APPLE_SERVICE_REDIRECT_URI = exports.APPLE_SERVICE_CLIENT_ID = exports.DISCOVER_MODE = exports.PERIOD_NAMES = exports.ANNUALLY = exports.MONTHLY = exports.PREMIUM_ANNUALLY_PRICE = exports.PREMIUM_MONTHLY_PRICE = exports.SUBSCRIPTION_PLANS = exports.PREMIUM_DAILY_TEST = exports.PREMIUM_ANNUALLY_ANDROID = exports.PREMIUM_MONTHLY_ANDROID = void 0;
const react_native_1 = require("react-native");
exports.VOICE_CACHE_DIRECTORY_PATH = ``;
exports.CACHE_EXPIRATION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
exports.CACHE_EXPIRATION_DURATION_SEC = 24 * 60 * 60; // 24 hours in milliseconds
exports.MESSAGES_PER_PAGE = 20;
exports.ERROR_MSG = {
    firstName: "Please enter first name",
    lastName: "Please enter last name",
    email: "Please enter email",
    emailConfirm: "Please confirm your email",
    password: "Please enter password",
    invalidEmail: "Please enter valid email",
    requiredField: "This field required",
    terms: "Please accept terms & conditions",
    username: "Please enter Username",
    name: "Please enter Name",
    descriptionRequired: "Description is required to generate the profile image",
    nameRequired: `Character name is required to generate the profile image.`,
};
exports.PROMPT_ERROR_MSG = {
    systemPrompt: "Please enter valid system prompt",
    userPrompt: "Please enter valid user prompt",
};
exports.WELCOME_FIRST_TIME_TEXT = `Welcome! Looks like it’s your first time here, choose who you want to chat with...`;
exports.KNOWN_FOR_HELP_TEXT = `"Description" is a field that defines your character's identity. Einstein, a great scientist. Zeus, a Greek god. The White Rabbit, known for Alice in Wonderland. This field shapes your characters voice. For example, Sarah, an expert in gift-giving, becomes an authority on offering gift advice. Or Romulus, an expert on Marvel comics, offers knowledge about its stories and characters. Let your imagination thrive!`;
exports.EMAIL_REG = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
exports.MAX_IMAGE_GENERATION_LIMIT = 10;
exports.MAX_DOC_IMAGE_REGENERATION_LIMIT = 3;
exports.MAX_CHARACTER_LIMIT = 448000;
exports.WARNING_CHARACTER_QUOTA = 2;
exports.WARNING_MESSAGES_QUOTA = 49;
exports.SUBSCRIPTION_DATE_TIME_FORMAT = "MM A, DD MMMM YYYY";
exports.IGNORE_NESTED_SCROLL_LOG = "VirtualizedLists should never be nested";
exports.CUSTOM_USER_CATEGORY_NAME = "UGC";
exports.SIGN_IN_ENABLED = true;
exports.RESET_FEATURE_ENABLED = false;
exports.PROMPTS_FEATURE_ENABLED = true; //
exports.TUTOR_MODE_FEATURE_ENABLED = true; // needed for demo
exports.USE_CATEGORY_DEVICE_CACHE = false;
exports.USE_CHARACTER_DEVICE_CACHE = false;
exports.USE_APPEND_ALL_CATEGORY = true; // append "All" to category list
exports.AUTOPLAY_MESSAGE_SPEECH = true;
exports.AUTOPLAY_MESSAGE_INTRO_IMAGE = false;
exports.VOICES_LIST = ["alloy", "echo", "fable", "onyx", "nova", "shimmer"];
exports.DEFAULT_VOICE = "nova"; //com.apple.ttsbundle.Samantha-compact";
exports.DEFAULT_VOICE_SPEED_INDEX = 1; //com.apple.ttsbundle.Samantha-compact";
exports.VOICE_SPEED_LIST = [0.75, 1, 1.25, 1.5, 2];
exports.WEB_SOCKET_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IiQwdW5kI2VuVSRlcjIjIiwiaWF0IjoxNTE2MjM5MDIyfQ.cGZ5s9UVRg-yRBtFYiCs0oHAZErWOfEKFAHOMLeDINs";
exports.OPENAI_API_KEY = "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"; //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"; //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"; //
exports.POSTHOG_API_KEY = 'phc_tacm28ZEgfg00zowEROoLBg8JWFtOv24vNaM3GuSv1U';
exports.OPEN_AI_VOICE_MODEL = "tts-1";
exports.OPEN_API_SPEECH_API = "https://api.openai.com/v1/audio/speech";
exports.DEFAULT_SPEED_RATE = 1;
exports.BREAKPOINT_XS = "xs"; //xs /lg
exports.SIDEBAR_MAX_WIDTH = 425;
exports.DISCOVER_SIDE_TAB_WIDTH = 81;
exports.TAB_PORTRAIT_SIDEBAR_MAX_WIDTH = 300;
exports.TAB_CHARACTER_COLUMN_COUNT = 5;
exports.SOCIAL_LOGIN_ENABLED = true;
exports.ADMIN_EMAILS = [
    "gabe@add.app", // Gabe
    "gabe@naafilms.com", // Gabe
    "herrymakker@gmail.com", // Herry
    "herrysingh1@outlook.com", // Herry
    "mr.manpreetbhullar@gmail.com", // Manu
    "testinguserplotpoint@gmail.com", // Manu
    "hiteshkumawat1302@gmail.com", // Hitesh
    "qadev.automation23@gmail.com", // Hitesh
    "oliver.lisian@gmail.com", // Lisian
    "fabiolvsantos93@gmail.com", // Fabio
    // "ek.albela@gmail.com", //inder gmail
    // "northwebstudio@gmail.com", //inder iphone
    // "goto.inder@gmail.com",
    // "siddheshwarl@agsft.com", //Akshay
    // "kakshay@agsft.com", //Akshay
];
exports.NegativePrompt = "double image, mirror image, statue, bad anatomy, misshapen face, fused fingers, incorrect hands, a face without detail, pastel colours, missing facial feature, extra eyes, 2 people, low quality, bad quality, bad textures, dull colours, lack of shine, bad formation, abstract, disfigured, deformed, disfigured, bad art, deformed, poorly drawn, extra limbs, close up, monochrome, weird colours, blurry, watermark, blur haze, 2 heads, elongated neck, cropped image, out of frame, draft,  poorly drawn face, missing limb, cut-off, over-saturated, grain, low resolution, bad anatomy, poorly drawn face, mutation, mutated, floating limbs, out of focus, disgusting, gross proportions";
exports.REVENUE_CAT_API_KEY = react_native_1.Platform?.OS === "android" ? "goog_ItqWAEdtGbvCYoNcBXLcatYpnxm" : "appl_rvoUPjvFEkWLvJJdpyruJwkFlWU";
exports.FB_WEB_CLIENT_ID = "956157246644-2q7rmerq7enqhl8jt8sj3ivorub7frpe.apps.googleusercontent.com";
exports.IOS_CLIENT_ID = '956157246644-53tjkn3mv9jq2bjuf5nkhsjt30vm2kfp.apps.googleusercontent.com';
exports.ENABLE_PREMIUM_DAILY_TEST = false;
exports.PREMIUM_MONTHLY = "PREMIUM_MONTHLY";
exports.PREMIUM_ANNUALLY = "PREMIUM_ANNUALLY";
exports.PREMIUM_MONTHLY_ANDROID = "premium_monthly:monthly";
exports.PREMIUM_ANNUALLY_ANDROID = "premium_annually:annually";
exports.PREMIUM_DAILY_TEST = "PREMIUM_DAILY_TEST";
exports.SUBSCRIPTION_PLANS = [
    exports.PREMIUM_MONTHLY, // don't change indexs : 1
    exports.PREMIUM_ANNUALLY, // don't change indexed: 2
    exports.PREMIUM_MONTHLY_ANDROID, // don't change indexed: 3
    exports.PREMIUM_ANNUALLY_ANDROID, // don't change indexed: 4
    exports.PREMIUM_DAILY_TEST,
    'premium_monthly',
    'monthly',
    'premium_annually',
];
exports.PREMIUM_MONTHLY_PRICE = 10;
exports.PREMIUM_ANNUALLY_PRICE = 35;
exports.MONTHLY = 'Monthly';
exports.ANNUALLY = 'Annually';
exports.PERIOD_NAMES = [exports.MONTHLY, exports.ANNUALLY];
exports.DISCOVER_MODE = true;
exports.APPLE_SERVICE_CLIENT_ID = "com.audiodesigndesk.ai.sid";
exports.APPLE_SERVICE_REDIRECT_URI = "https://auth-makrai.firebaseapp.com/__/auth/handler";
exports.FREECARDBULLETSTEXTS = [
    'One-time 50 message bonus post sign-up',
    '5 image generations in messages',
    '10 messages daily limit',
    'Up to 5 voice conversations per day',
    'Create up to 3 characters',
    'Limited Tutor Mode',
];
exports.PREMIUMCARDBULLETSTEXTS = [
    '500 messages per month',
    'Bring ideas to life with  image generation',
    'No daily limit',
    'Voice conversations equal to the number of messages',
    'Create up to 50 characters',
    'Full access to Tutor Mode',
];
exports.FAQS = [
    {
        question: "Is there an API for TalkToo?",
        answer: "Currently, TalkToo does not offer a public API. However, we're always interested in unique use cases. Feel free to reach out with your ideas.",
    },
    {
        question: "How does TalkToo handle NSFW content and swearing?",
        answer: "We aim to maintain a respectful and safe environment. NSFW content and excessive swearing are moderated according by our AI.",
    },
    {
        question: "How can I access more messages or features?",
        answer: "Consider our subscription plans for additional messages, voice conversations, character creations, and full access to Tutor Mode. These plans are designed to enhance your TalkToo experience.",
    },
    {
        question: "When are new updates released?",
        answer: "We release updates regularly to introduce new features and improvements. Stay tuned to our notifications.",
    },
    {
        question: "How can I share my feedback or suggest new features?",
        answer: "Your feedback is invaluable. Please use the app's feedback section. We are always listening.",
    },
    {
        question: "Is there a way to increase the daily message limit?",
        answer: "We understand that a limit of 10 messages per day might not suffice for everyone. We do offer 50 messages on sign up. By subscribing to our service, you can increase your monthly message quota to 500 messages. This upgrade is part of our effort to provide a more flexible and enriched chatting experience. Visit our subscription page in the app for more information on plans and benefits.",
    },
    {
        question: "Can TalkToo creators see my conversations?",
        answer: "Your feedback is invaluable. Please use the app's feedback section. We are always listening.",
    },
    {
        question: "Are my characters private?",
        answer: "Characters can be set to public or private, depending on your preference. For more details, refer to our Privacy Policy.",
    },
    {
        question: "hat technology powers TalkToo?",
        answer: "TalkToo is built on advanced deep learning models, specifically designed for engaging conversations. Our technology learns from vast amounts of text to predict and generate responses that make chatting with characters feel real and immersive.",
    },
];
exports.CHARACTER_PROMPTS_PLACEHOLDER = [
    'The Zany Educator',
    'Soccer Supermom',
    'YouTube Whizkid',
    'Clueless Conspiracy Theorist',
    'Gossipy Neighbor',
    'Eccentric Enthusiast',
    'Dance Dynamo Mom',
    'Office Oddball Intern',
    'Call-Center Comedian',
    'Influential Insta-Star',
    'Activist Dynamo',
    'The Nosy Neighbor',
];
exports.DOCUMENT_MAXIMUM_SIZE_MB = 30;
exports.DEFAULT_ERROR_MESSAGE = "A error occured on your request.";
exports.NOT_FOUND_MESSAGE = "Uh-oh, dead end! But there’s more to explore here!";
exports.SESSION_OUT_MESSAGE = "Session out! Please log-in again.";
exports.WRONG_DOCUMENT_FILE_TYPE_MESSAGE = "You must provide a PDF file for documents.";
exports.WRONG_IMAGE_FILE_TYPE_MESSAGE = "Looks like that’s not a valid image. Let’s try again!";
exports.DOCUMENT_SIZE_LIMIT_EXCCEDED = "Oops! Your file is too big. Please upload a smaller file and try again.";
exports.OUT_OF_QUOTA_MESSAGES = "Bummer! Limit reached. Explore docs, share summaries.";
exports.AVAILABLE_FONTS = [
    {
        id: 'geist-sans',
        name: 'Geist Sans',
        fontFamily: 'Geist',
        variants: {
            regular: 'Geist',
            light: 'Geist-Light',
            medium: 'Geist-Medium',
            bold: 'Geist-Bold'
        }
    },
    {
        id: 'inter',
        name: 'Inter',
        fontFamily: 'Inter',
        variants: {
            regular: 'Inter',
            light: 'Inter-Light',
            medium: 'Inter',
            bold: 'Inter-Bold'
        }
    },
    {
        id: 'opendyslexic',
        name: 'OpenDyslexic',
        fontFamily: 'OpenDyslexic',
        variants: {
            regular: 'OpenDyslexic',
            bold: 'OpenDyslexic-Bold'
        }
    },
    {
        id: 'noto-serif',
        name: 'Noto Serif',
        fontFamily: 'NotoSerif',
        variants: {
            regular: 'NotoSerif',
            bold: 'NotoSerif-Bold'
        }
    }
];
