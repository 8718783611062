"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const colors_1 = require("../../common/colors");
exports.styles = react_native_1.StyleSheet.create({
    screenContainer: {
        flex: 1,
        paddingHorizontal: 24,
        paddingBottom: 32,
    },
    rootContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    stickyBottom: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        flexDirection: 'row',
        padding: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    modalContent: {
        backgroundColor: colors_1.Colors.DARK_600,
        width: '85%',
        maxWidth: 528,
        height: 668,
        padding: 20,
        borderRadius: 16,
    },
    mobileModalContainer: {
        width: '95%',
    },
    header: {
        gap: 16,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 22,
    },
    headerTextContainer: {
        gap: 10,
    },
    headerText: {
        color: colors_1.Colors.white,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 16,
    },
    subtitle: {
        color: colors_1.Colors.DARK_TEXT,
        fontSize: 14,
        lineHeight: 14,
    },
    contentContainer: {
        gap: 16,
    },
    mainArea: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    mainContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    buttonsArea: {
        gap: 10,
    },
    inputArea: {
        gap: 18,
    },
    sectionArea: {
        gap: 8,
    },
    sectionHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    sectionLabel: {
        color: '#FFF',
        lineHeight: 19.6,
    },
    scrollContainer: {
        flex: 1,
        width: '100%',
    },
    scrollContent: {
        flexGrow: 1,
        gap: 16,
    },
    inputAnnotation: {
        color: 'rgba(255, 255, 255, 0.40)',
        fontSize: 12,
        fontStyle: 'italic',
        lineHeight: 16.8,
    },
    buttonsContainer: {
        gap: 6,
        flexDirection: 'row',
    },
    input: {
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        color: 'white',
        lineHeight: 19.6,
        paddingVertical: 10,
        paddingHorizontal: 16,
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.16)',
        borderRadius: 4,
        outline: 'none',
        minHeight: 300,
    },
    focusInput: {
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        color: 'white',
        lineHeight: 19.6,
        paddingVertical: 10,
        paddingHorizontal: 16,
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.16)',
        borderRadius: 4,
        outline: 'none',
    },
    chipsContainer: {
        backgroundColor: '#1B1B1F',
        height: 96,
        padding: 6,
        borderRadius: 8,
    },
    chipsScrollContainer: {
        gap: 6,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    bottomContainer: {
        gap: 12,
        flexDirection: 'row',
        alignItems: 'center',
    },
    languageButton: {
        flex: 1,
        borderRadius: 8,
        borderColor: `${colors_1.Colors.white}20`,
        borderWidth: 1,
    },
    submitButton: {
        flex: 2,
        minWidth: 200,
    },
    submitButtonMobile: {
        minWidth: 156,
    },
});
