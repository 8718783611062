"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MenuItem;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const AppText_1 = require("../../AppText");
const colors_1 = require("../../../common/colors");
const spacing_1 = require("../../../common/spacing");
function MenuItem({ icon, action, children, isDestructive = false }) {
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(native_base_1.Menu.Item, { onPress: action, onPointerEnter: () => setIsHovered(true), onPointerLeave: () => setIsHovered(false), style: [
            styles.item,
            isHovered && (isDestructive ? styles.itemHoveredDestructive : styles.itemHovered)
        ], children: [react_1.default.cloneElement(icon, {
                color: isDestructive ? colors_1.Colors.red : colors_1.Colors.white
            }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [
                    styles.text,
                    isDestructive && styles.textDestructive,
                    isHovered && (isDestructive ? styles.textDestructiveHovered : styles.textHovered)
                ], children: children })] }));
}
;
const styles = react_native_1.StyleSheet.create({
    item: {
        backgroundColor: 'transparent',
        paddingVertical: 10,
        paddingHorizontal: 4,
    },
    itemHovered: {
        backgroundColor: `${colors_1.Colors.PRIMARY_300}33`,
    },
    itemHoveredDestructive: {
        backgroundColor: `${colors_1.Colors.red}20`,
    },
    pressable: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: spacing_1.Spacing.SPACING_MD,
    },
    text: {
        color: colors_1.Colors.white,
        fontWeight: '500',
        lineHeight: 20,
    },
    textDestructive: {
        color: colors_1.Colors.red,
    },
    textHovered: {
        color: colors_1.Colors.white,
    },
    textDestructiveHovered: {
        color: colors_1.Colors.red,
    }
});
