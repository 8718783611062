"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericChip = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const colors_1 = require("../../common/colors");
const AppText_1 = require("../AppText");
const GenericChip = ({ leftContent, rightContent, selected, onPress }) => {
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    const renderLeftContent = () => {
        switch (leftContent.type) {
            case 'icon':
                return ((0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: leftContent.value, size: 16, color: selected ? colors_1.Colors.PRIMARY_300 : colors_1.Colors.DARK_300 }));
            case 'emoji':
                return ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.aspectChipText, children: leftContent.value.toString() }));
            case 'text':
                return ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.aspectChipText, children: leftContent.value.toString() }));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { onPress: onPress, onHoverIn: () => setIsHovered(true), onHoverOut: () => setIsHovered(false), style: [
            styles.aspectChip,
            selected && styles.selectedAspectChip,
            isHovered && styles.hoveredChip
        ], children: [renderLeftContent(), rightContent && ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.aspectChipText, children: rightContent }))] }));
};
exports.GenericChip = GenericChip;
const styles = react_native_1.StyleSheet.create({
    aspectChip: {
        flex: 1,
        borderRadius: 30,
        backgroundColor: colors_1.Colors.DARK_700,
        borderColor: colors_1.Colors.DARK_600,
        borderWidth: 1,
        paddingHorizontal: 16,
        paddingVertical: 8,
        margin: 4,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "row",
        gap: 8,
    },
    selectedAspectChip: {
        backgroundColor: colors_1.Colors.PRIMARY_700,
        borderColor: colors_1.Colors.PRIMARY_300,
    },
    hoveredChip: {
        backgroundColor: colors_1.Colors.PRIMARY_700,
    },
    aspectChipText: {
        fontSize: 14,
        color: colors_1.Colors.white,
        textAlign: 'center'
    },
});
