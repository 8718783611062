"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = UploadControl;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const useAppMessage_1 = require("../../../hooks/useAppMessage");
function UploadControl({ multiple, fileType, invalidTypeMessage, triggerRef, dragAndDrop, disabled, onPress, fileAction, wrapperStyle, children, }) {
    const controlId = (0, react_1.useId)();
    const { showNotification } = (0, useAppMessage_1.useAppMessage)();
    const [isNotDragging, setIsNotDragging] = (0, react_1.useState)(true);
    const containerRef = (0, react_1.useRef)(null);
    const inputRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const uploadListenerAction = () => {
            inputRef.current?.click();
        };
        if (Array.isArray(triggerRef)) {
            triggerRef.forEach(ref => {
                ref?.current?.addEventListener('click', uploadListenerAction);
            });
        }
        else {
            triggerRef?.current?.addEventListener('click', uploadListenerAction);
        }
        return () => {
            if (Array.isArray(triggerRef)) {
                triggerRef.forEach(ref => {
                    ref?.current?.removeEventListener('click', uploadListenerAction);
                });
            }
            else {
                triggerRef?.current?.removeEventListener('click', uploadListenerAction);
            }
        };
    }, [triggerRef]);
    (0, react_1.useEffect)(() => {
        const pointerEnterAction = () => {
            setTimeout(() => setIsNotDragging(true));
        };
        const pointerLeaveAction = () => {
            setIsNotDragging(false);
        };
        containerRef.current?.addEventListener('pointerenter', pointerEnterAction);
        containerRef.current?.addEventListener('pointerleave', pointerLeaveAction);
        return () => {
            containerRef.current?.removeEventListener('pointerenter', pointerEnterAction);
            containerRef.current?.removeEventListener('pointerleave', pointerLeaveAction);
        };
    }, []);
    const handleFileInput = (0, react_1.useCallback)(({ target: { files } }) => {
        const uploadSource = multiple ? [...files] : files[0];
        if (!fileType
            || typeof fileType === 'string'
            ? Array.isArray(uploadSource)
                ? uploadSource.every((file) => fileType === file.type) : fileType === uploadSource.type
            : Array.isArray(uploadSource)
                ? uploadSource.every((file) => fileType.some((allowedType) => allowedType === file.type))
                : fileType.some((allowedType) => allowedType === uploadSource.type)) {
            fileAction(uploadSource);
        }
        else {
            showNotification('Error', invalidTypeMessage ?? '', 'error');
        }
        ;
    }, [fileAction]);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { ref: containerRef, onPress: onPress, style: typeof wrapperStyle === 'function'
            ? (pressed) => [styles.container, wrapperStyle(pressed)]
            : [styles.container, wrapperStyle], children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: !triggerRef ? controlId : undefined, style: {
                    ...styles.label,
                    cursor: triggerRef ? 'default' : 'inherit',
                }, children: children }), (0, jsx_runtime_1.jsx)("input", { ref: inputRef, id: controlId, type: 'file', accept: typeof fileType === 'string' ? fileType : fileType?.join(', '), multiple: multiple, disabled: disabled, value: '', onChange: handleFileInput, style: {
                    ...styles.fileControl,
                    ...(dragAndDrop && styles.dragAndDrop),
                    ...(isNotDragging && styles.notDragged),
                } })] }));
}
;
const styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
    },
    fileControl: {
        display: 'none',
    },
    dragAndDrop: {
        width: '100%',
        height: '100%',
        opacity: 0,
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
    },
    notDragged: {
        zIndex: -1,
    },
    label: {
        display: 'flex',
        flex: 1,
    }
});
