"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Linkable;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
function Linkable({ href, children, ...restProps }) {
    if (href) {
        return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => react_native_1.Linking.openURL(href), ...restProps, children: children }));
    }
    ;
    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { ...restProps, children: children }));
}
;
