"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ReadDocument;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_track_player_1 = __importStar(require("react-native-track-player"));
const react_native_reanimated_1 = __importStar(require("react-native-reanimated"));
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
const useAudio_1 = require("../../hooks/useAudio");
const AppText_1 = require("../AppText");
const utils_1 = require("../../helper/utils");
const spacing_1 = require("../../common/spacing");
const events = [react_native_track_player_1.Event.PlaybackState];
const COMPACT_SIZE = 375;
function ReadDocument({ source, onClose }) {
    const { position, duration } = (0, react_native_track_player_1.useProgress)();
    const { cacheAudioFile, getAudioRef } = (0, useAudio_1.useAudio)();
    const trackId = (0, react_1.useId)();
    const [trackProgress, setTrackProgress] = (0, react_1.useState)({ position: 0, duration: 0 });
    const [containerWidth, setContainerWidth] = (0, react_1.useState)(0);
    const [isPlaying, setIsPlaying] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isEnded, setIsEnded] = (0, react_1.useState)(false);
    const trackRef = (0, react_1.useRef)();
    const hasReadStartedRef = (0, react_1.useRef)(false);
    const isCurrentTrackRef = (0, react_1.useRef)(false);
    const forceStopRef = (0, react_1.useRef)(false);
    const abortControllerRef = (0, react_1.useRef)();
    const loadingSpinnerRotation = (0, react_native_reanimated_1.useSharedValue)(0);
    const animatedLoadingStyles = (0, react_native_reanimated_1.useAnimatedStyle)(() => ({
        transform: [{ rotateZ: `${loadingSpinnerRotation.value}deg` }],
    }), [loadingSpinnerRotation]);
    (0, react_native_track_player_1.useTrackPlayerEvents)(events, (event) => {
        if (event.type === react_native_track_player_1.Event.PlaybackState && isCurrentTrackRef.current) {
            switch (event.state) {
                case "loading":
                    if (forceStopRef.current) {
                        react_native_track_player_1.default.stop();
                        forceStopRef.current = false;
                    }
                    break;
                case "playing":
                    setIsPlaying(true);
                    break;
                case "stopped":
                case "paused":
                    setIsPlaying(false);
                    break;
                case "ended":
                    setIsPlaying(false);
                    setIsEnded(true);
                    forceStopRef.current = true;
                    break;
                case "error":
                    setIsPlaying(false);
                    break;
            }
        }
    });
    const resumeTrack = (0, react_1.useCallback)(async (track, position, abortController) => {
        if (track) {
            try {
                !abortController?.signal.aborted && await react_native_track_player_1.default.load(track);
            }
            catch (error) {
                if (error.code === 7000) {
                    !abortController?.signal.aborted && await react_native_track_player_1.default.load(track);
                    // reload after stop
                }
            }
            !abortController?.signal.aborted && await react_native_track_player_1.default.play();
            !abortController?.signal.aborted && await react_native_track_player_1.default.seekTo(position);
        }
    }, []);
    const playMessage = (0, react_1.useCallback)(async (message, messageId, abortController) => {
        const sourceFileReference = getAudioRef(messageId);
        // !abortController?.signal.aborted && await TrackPlayer.reset();
        if (!sourceFileReference.isCached) {
            !abortController?.signal.aborted && await cacheAudioFile(sourceFileReference, message);
        }
        !abortController?.signal.aborted && await react_native_track_player_1.default.add([{
                url: sourceFileReference.url,
                title: trackId,
            }], null);
        const track = ((await react_native_track_player_1.default.getQueue()).find((track) => track.title === trackId));
        setIsLoading(false);
        trackRef.current = track;
        if (track) {
            await resumeTrack(track, 0, abortController);
        }
    }, [trackId]);
    const initSpeech = (0, react_1.useCallback)(async (abortController) => {
        setIsLoading(true);
        hasReadStartedRef.current = true;
        // !abortController?.signal.aborted && await TrackPlayer.reset();
        if (typeof source === 'string') {
            try {
                !abortController?.signal.aborted && await react_native_track_player_1.default.add({
                    url: source,
                    title: trackId,
                }, null);
                const track = ((await react_native_track_player_1.default.getQueue()).find((track) => track.title === trackId));
                trackRef.current = track;
                if (track) {
                    await resumeTrack(track, 0, abortController);
                }
            }
            catch {
            }
            setIsLoading(false);
        }
        else if ('fiveMinWrapAudioUrl' in source) {
            // generateFiveMinutesAudio(source, () => {});
        }
        else {
            playMessage(source.message, source.id.toString(), abortController);
        }
        ;
    }, [source, trackId, playMessage]);
    (0, react_1.useEffect)(() => {
        if (isLoading) {
            loadingSpinnerRotation.value = (0, react_native_reanimated_1.withRepeat)((0, react_native_reanimated_1.withTiming)(360, {
                duration: 2000,
                easing: react_native_reanimated_1.Easing.linear,
            }), 200);
            return () => {
                (0, react_native_reanimated_1.cancelAnimation)(loadingSpinnerRotation);
                loadingSpinnerRotation.value = 0;
            };
        }
    }, [isLoading]);
    (0, react_1.useEffect)(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        initSpeech(abortController);
        return () => {
            react_native_track_player_1.default.getActiveTrack().then((track) => {
                if (track?.title === trackRef.current?.title) {
                    react_native_track_player_1.default.pause();
                }
            });
            abortController.abort();
        };
    }, [initSpeech]);
    (0, react_1.useEffect)(() => {
        react_native_track_player_1.default.addEventListener(react_native_track_player_1.Event.PlaybackActiveTrackChanged, (event) => {
            if (event.track?.title === trackId) {
                isCurrentTrackRef.current = true;
            }
            else {
                isCurrentTrackRef.current = false;
                setIsPlaying(false);
            }
        });
    }, [trackId]);
    (0, react_1.useEffect)(() => {
        if (isCurrentTrackRef.current && duration) {
            setTrackProgress({ position, duration });
        }
        ;
    }, [position, duration]);
    const handleLayout = (0, react_1.useCallback)(({ nativeEvent: { layout: { width } } }) => {
        setContainerWidth(width);
    }, []);
    const handleControlPress = (0, react_1.useCallback)((trackPosition) => {
        const abortController = abortControllerRef.current;
        if (!isLoading) {
            setIsEnded(false);
            switch (true) {
                case !hasReadStartedRef.current:
                    initSpeech(abortController);
                    break;
                case isPlaying:
                    react_native_track_player_1.default.pause();
                    break;
                default:
                    if (trackRef.current !== undefined) {
                        resumeTrack(trackRef.current, trackPosition, abortController);
                    }
            }
        }
    }, [
        trackProgress,
        isPlaying,
        isLoading,
        initSpeech,
        resumeTrack
    ]);
    const handleRewind = (0, react_1.useCallback)(() => {
        if (trackProgress.position - 10 < 0) {
            react_native_track_player_1.default.seekTo(0);
        }
        else {
            react_native_track_player_1.default.seekTo(trackProgress.position - 10);
        }
        ;
        setIsEnded(false);
    }, [trackProgress]);
    const handleForward = (0, react_1.useCallback)(() => {
        const totalDuration = Math.floor(trackProgress.duration);
        if (trackProgress.position + 10 > totalDuration) {
            react_native_track_player_1.default.seekTo(totalDuration);
        }
        else {
            react_native_track_player_1.default.seekTo(trackProgress.position + 10);
        }
    }, [trackProgress]);
    return ((0, jsx_runtime_1.jsxs)(react_native_reanimated_1.default.View, { onLayout: handleLayout, style: styles.root, entering: react_native_reanimated_1.FadeInUp, exiting: react_native_reanimated_1.FadeOutUp, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { children: (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { activeOpacity: 0.7, onPress: () => handleControlPress(isEnded ? 0 : trackProgress.position), style: styles.iconContainer, children: (0, jsx_runtime_1.jsx)(react_native_reanimated_1.default.View, { style: animatedLoadingStyles, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: isLoading && pro_solid_svg_icons_1.faSpinner
                                || isEnded && pro_solid_svg_icons_1.faRotateLeft
                                || (isPlaying ? pro_solid_svg_icons_1.faPause : pro_solid_svg_icons_1.faPlay), size: 16, color: "white", style: animatedLoadingStyles }) }) }) }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: "white", fontSize: containerWidth <= COMPACT_SIZE ? 24 : 40, fontWeight: "600", lineHeight: 48, flex: 1, children: (0, utils_1.formatTime)(trackProgress.position) }), (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { activeOpacity: 0.7, onPress: handleRewind, style: styles.iconContainer, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_regular_svg_icons_1.faBackwardFast, size: 20, color: 'white' }) }), (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { activeOpacity: 0.7, style: styles.iconContainer, onPress: handleForward, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_regular_svg_icons_1.faFastForward, size: 20, color: "white" }) }), onClose && ((0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { activeOpacity: 0.7, style: styles.iconContainer, onPress: onClose, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_regular_svg_icons_1.faXmark, size: 20, color: "white" }) }))] }));
}
;
const styles = react_native_1.StyleSheet.create({
    root: {
        backgroundColor: '#1B1B22',
        gap: spacing_1.Spacing.SPACING_3XL,
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: spacing_1.Spacing.SPACING_XL,
        paddingVertical: 14,
        borderRadius: 12,
    },
    iconContainer: {
        backgroundColor: '#A0A0A329',
        padding: 8,
        borderRadius: 16,
    },
});
