"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDocumentsContext = void 0;
exports.default = DocumentsProvider;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
;
const context = (0, react_1.createContext)({
    generationAbortController: new AbortController(),
    abortGenerationStreaming: () => { },
});
const Provider = context.Provider;
const useDocumentsContext = () => (0, react_1.useContext)(context);
exports.useDocumentsContext = useDocumentsContext;
function DocumentsProvider({ children }) {
    const [generationAbortController, setGenerationAbortController,] = (0, react_1.useState)(new AbortController());
    const abortGenerationStreaming = (0, react_1.useCallback)(() => {
        generationAbortController.abort();
        setGenerationAbortController(new AbortController());
    }, [generationAbortController]);
    return ((0, jsx_runtime_1.jsx)(Provider, { value: {
            generationAbortController,
            abortGenerationStreaming,
        }, children: children }));
}
;
