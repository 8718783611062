"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const AppText_1 = require("../../component/AppText");
const colors_1 = require("../../common/colors");
const SourceLimitBar = ({ current, total }) => {
    const progress = (current / total) * 100;
    const animatedWidth = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    (0, react_1.useEffect)(() => {
        react_native_1.Animated.timing(animatedWidth, {
            toValue: progress,
            duration: 250,
            useNativeDriver: false,
        }).start();
    }, [progress]);
    const widthInterpolated = animatedWidth.interpolate({
        inputRange: [0, 100],
        outputRange: ['0%', '100%'],
    });
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.container, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.progressBarBackground, children: (0, jsx_runtime_1.jsx)(react_native_1.Animated.View, { style: [
                        styles.progressBarFill,
                        { width: widthInterpolated },
                    ] }) }), (0, jsx_runtime_1.jsxs)(AppText_1.AppText, { style: styles.text, children: [current, " / ", total, " Sources Added"] })] }));
};
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: colors_1.Colors.DARK_600,
        padding: 16,
        borderRadius: 8,
        gap: 8,
    },
    progressBarBackground: {
        width: '100%',
        height: 8,
        backgroundColor: colors_1.Colors.DARK_700,
        borderRadius: 4,
        overflow: 'hidden',
    },
    progressBarFill: {
        height: '100%',
        backgroundImage: `linear-gradient(180deg, ${colors_1.Colors.GRADIENT[0]}, ${colors_1.Colors.GRADIENT[1]})`,
    },
    text: {
        color: colors_1.Colors.DARK_200,
        fontSize: 11,
    },
});
exports.default = SourceLimitBar;
