"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsImageLoading = exports.selectIsLoading = exports.selectDocumentShareState = exports.selectIsPdfOpen = exports.selectIsAudioConfigOpen = exports.selectIsSourcesOpen = exports.selectIsDocumentConversationOpen = exports.selectDocumentUploadComplete = exports.selectNote = exports.selectDocumentsSourcesLoading = exports.selectSelectedFileIds = exports.selectDocumentJson = exports.selectDocumentStreaming = exports.selectDocumentSummary = void 0;
const selectDocumentSummary = (state) => state.documents.summary;
exports.selectDocumentSummary = selectDocumentSummary;
const selectDocumentStreaming = (state) => state.documents.streaming;
exports.selectDocumentStreaming = selectDocumentStreaming;
const selectDocumentJson = (state) => state.documents.documentJson;
exports.selectDocumentJson = selectDocumentJson;
const selectSelectedFileIds = (state) => state.documents.selectedFileIds;
exports.selectSelectedFileIds = selectSelectedFileIds;
const selectDocumentsSourcesLoading = (state) => (state.documents.documentsSourcesLoading);
exports.selectDocumentsSourcesLoading = selectDocumentsSourcesLoading;
const selectNote = (state) => state.documents.note;
exports.selectNote = selectNote;
const selectDocumentUploadComplete = (state) => state.documents.documentUploadComplete;
exports.selectDocumentUploadComplete = selectDocumentUploadComplete;
const selectIsDocumentConversationOpen = (state) => (state.documents.isConversationOpen);
exports.selectIsDocumentConversationOpen = selectIsDocumentConversationOpen;
const selectIsSourcesOpen = (state) => state.documents.isSourcesOpen;
exports.selectIsSourcesOpen = selectIsSourcesOpen;
const selectIsAudioConfigOpen = (state) => state.documents.isAudioConfigOpen;
exports.selectIsAudioConfigOpen = selectIsAudioConfigOpen;
const selectIsPdfOpen = (state) => state.documents.isPdfOpen;
exports.selectIsPdfOpen = selectIsPdfOpen;
const selectDocumentShareState = (state) => state.documents.shareState;
exports.selectDocumentShareState = selectDocumentShareState;
const selectIsLoading = (state) => state.documents.isLoading;
exports.selectIsLoading = selectIsLoading;
const selectIsImageLoading = (state) => state.documents.isImageRegenerating;
exports.selectIsImageLoading = selectIsImageLoading;
