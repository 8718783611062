"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = UploadInput;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const AppButton_1 = require("../AppButton");
const AppText_1 = require("../AppText");
const colors_1 = require("../../common/colors");
function UploadInput({ icon, title, subtitle, fileType, fileAction, disabled, onPress, }) {
    return ((0, jsx_runtime_1.jsx)(AppButton_1.UploadControl, { fileAction: fileAction, fileType: fileType, multiple: true, disabled: disabled, onPress: onPress, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.container, children: [icon, (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.textContainer, children: [title && ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.title, children: title })), subtitle && ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.subtitle, children: subtitle }))] })] }) }));
}
;
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: 'rgba(101, 101, 105, 0.08)',
        gap: 16,
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 12,
    },
    textContainer: {
        gap: 2,
    },
    title: {
        color: colors_1.Colors.NEUTRAL_00,
        fontWeight: '500',
        lineHeight: 20,
    },
    subtitle: {
        color: colors_1.Colors.NEUTRAL_25,
        fontSize: 12,
        fontStyle: 'italic',
        lineHeight: 14,
    },
});
