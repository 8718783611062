"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = BigButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const _1 = require(".");
const react_native_1 = require("react-native");
const colors_1 = require("../../common/colors");
function BigButton({ onPress = () => { }, disabled, fullWidth = false, style, containerStyle, textStyle, children = '', customGradientColors, ...rest }) {
    const [isHovered, setIsHovered] = (0, react_1.useState)(false);
    const hoverGradientColors = (0, react_1.useMemo)(() => isHovered ? colors_1.Colors.GRADIENT_HOVER : undefined, [isHovered]);
    return ((0, jsx_runtime_1.jsx)(_1.GradientButton, { text: children, paddingH: 0, fullWidth: fullWidth, disabled: disabled, onPress: onPress, customGradientColors: customGradientColors ?? hoverGradientColors, style: containerStyle, customStyles: {
            ...styles,
            button: [
                { ...styles.button },
                style,
                isHovered && styles.buttonHover
            ],
            text: [textStyle, isHovered && styles.textHover],
        }, ...rest }));
}
const styles = react_native_1.StyleSheet.create({
    button: {
        height: 'auto',
        paddingHorizontal: 24,
        paddingVertical: 16,
    },
    buttonHover: {
        backgroundColor: `${colors_1.Colors.GRADIENT[0]}20`,
    },
    text: {
        paddingVertical: 0,
    },
    textHover: {
        color: '#FFFFFF',
    }
});
