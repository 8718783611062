"use strict";
// randomPasswordGenerator.ts
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomPassword = getRandomPassword;
// Expanded arrays with approximately 500 words each that can be used as back up
const verbs = [
    'accept', 'achieve', 'act', 'add', 'admire', 'admit', 'advise', 'afford', 'agree', 'alert',
    'allow', 'amuse', 'analyze', 'announce', 'annoy', 'answer', 'apologize', 'appear', 'applaud', 'appreciate',
    'approve', 'argue', 'arrange', 'arrest', 'arrive', 'ask', 'attach', 'attack', 'attempt', 'attend',
    'attract', 'avoid', 'bake', 'balance', 'ban', 'bang', 'bare', 'bat', 'bathe', 'battle',
    'be', 'beam', 'bear', 'beat', 'become', 'beg', 'begin', 'behave', 'believe', 'belong',
    'bend', 'bet', 'bind', 'bite', 'bless', 'blind', 'blink', 'blot', 'blow', 'blush',
    'boast', 'boil', 'bolt', 'bomb', 'book', 'bore', 'borrow', 'bounce', 'bow', 'box',
    'brake', 'branch', 'breathe', 'breed', 'bring', 'broadcast', 'brush', 'build', 'bump', 'burn',
    'burst', 'bury', 'buy', 'calculate', 'call', 'camp', 'care', 'carry', 'carve', 'cause',
    'challenge', 'change', 'charge', 'chase', 'cheat', 'check', 'cheer', 'chew', 'choke', 'choose',
    'chop', 'claim', 'clap', 'clean', 'clear', 'cling', 'clip', 'close', 'coach', 'coil',
    'collect', 'color', 'comb', 'come', 'command', 'communicate', 'compare', 'compete', 'complain', 'complete',
    'concentrate', 'concern', 'confess', 'confuse', 'connect', 'consider', 'consist', 'contain', 'continue', 'copy',
    'correct', 'cough', 'count', 'cover', 'crack', 'crash', 'crawl', 'cross', 'crush', 'cry',
    'cure', 'curl', 'curve', 'cut', 'cycle', 'dam', 'damage', 'dance', 'dare', 'deal',
    'decay', 'deceive', 'decide', 'decorate', 'delay', 'delight', 'deliver', 'depend', 'describe', 'desert',
    'deserve', 'destroy', 'detect', 'develop', 'die', 'dig', 'direct', 'disagree', 'disappear', 'disapprove',
    'disarm', 'discover', 'dislike', 'divide', 'do', 'doubt', 'drag', 'drain', 'dream', 'dress',
    'drink', 'drive', 'drop', 'drown', 'drum', 'dry', 'dust', 'earn', 'educate', 'embarrass',
    'employ', 'empty', 'encourage', 'end', 'enjoy', 'enter', 'entertain', 'escape', 'examine', 'excite',
    'excuse', 'exercise', 'exist', 'expand', 'expect', 'explain', 'explode', 'extend', 'face', 'fade',
    'fail', 'fancy', 'fasten', 'fax', 'fear', 'feed', 'feel', 'fight', 'fill', 'film',
    'fire', 'fit', 'fix', 'flap', 'flash', 'float', 'flood', 'flow', 'flower', 'fold',
    'follow', 'fool', 'force', 'form', 'found', 'frame', 'freeze', 'frighten', 'fry', 'gather',
    'gaze', 'gel', 'get', 'give', 'glow', 'glue', 'grab', 'grate', 'grease', 'greet',
    'grin', 'grip', 'groan', 'grow', 'guess', 'guide', 'hammer', 'hand', 'handle', 'hang',
    'happen', 'harass', 'harm', 'hate', 'haunt', 'head', 'heal', 'heap', 'hear', 'heat',
    'help', 'hook', 'hop', 'hope', 'hover', 'hug', 'hum', 'hunt', 'hurry', 'hurt',
    'hypothesize', 'identify', 'ignore', 'imagine', 'impress', 'improve', 'include', 'increase', 'influence', 'inform',
    'inject', 'injure', 'instruct', 'intend', 'interest', 'interfere', 'interrupt', 'introduce', 'invent', 'invite',
    'irritate', 'itch', 'jail', 'jam', 'jog', 'join', 'joke', 'judge', 'juggle', 'jump',
    'keep', 'kick', 'kill', 'kiss', 'kneel', 'knit', 'knock', 'knot', 'know', 'label',
    'land', 'last', 'laugh', 'launch', 'lean', 'learn', 'leave', 'lend', 'let', 'level',
    'license', 'lick', 'lie', 'lift', 'light', 'like', 'list', 'listen', 'live', 'load',
    'lock', 'long', 'look', 'lose', 'love', 'maintain', 'make', 'man', 'manage', 'march',
    'mark', 'marry', 'match', 'mate', 'matter', 'mean', 'measure', 'meet', 'melt', 'memorize',
    'mend', 'mess up', 'milk', 'mine', 'miss', 'mix', 'moan', 'moor', 'mourn', 'move',
    'mow', 'muddle', 'mug', 'multiply', 'murder', 'nail', 'name', 'need', 'neglect', 'negotiate',
    'nod', 'note', 'notice', 'number', 'obey', 'object', 'observe', 'obtain', 'occur', 'offend',
    'offer', 'open', 'order', 'overflow', 'owe', 'own', 'pack', 'paddle', 'paint', 'park',
    'part', 'pass', 'paste', 'pat', 'pause', 'pay', 'peck', 'pedal', 'peel', 'peep',
    'perform', 'permit', 'phone', 'pick', 'pinch', 'pine', 'place', 'plan', 'play', 'please',
    'plug', 'point', 'poke', 'polish', 'pop', 'possess', 'post', 'pour', 'practice', 'pray',
    'preach', 'precede', 'prefer', 'prepare', 'present', 'preserve', 'press', 'pretend', 'prevent', 'prick',
    'print', 'produce', 'program', 'promise', 'protect', 'provide', 'pull', 'pump', 'punch', 'puncture',
    'punish', 'push', 'question', 'queue', 'race', 'rain', 'raise', 'reach', 'realize', 'receive',
    'recognize', 'record', 'reduce', 'reflect', 'refuse', 'regret', 'reign', 'reject', 'rejoice', 'relax',
    'release', 'rely', 'remain', 'remember', 'remind', 'remove', 'repair', 'repeat', 'replace', 'reply',
    'report', 'reproduce', 'request', 'rescue', 'retire', 'return', 'rhyme', 'ride', 'ring', 'rinse',
    'risk', 'rob', 'rock', 'roll', 'rot', 'rub', 'ruin', 'rule', 'run', 'rush',
    'sack', 'sail', 'satisfy', 'save', 'saw', 'scare', 'scatter', 'scold', 'scorch', 'scrape',
    'scratch', 'scream', 'screw', 'scribble', 'seal', 'search', 'see', 'sell', 'send', 'sense',
    'separate', 'serve', 'settle', 'shade', 'shake', 'shape', 'share', 'shave', 'shelter', 'shine',
    'shiver', 'shock', 'shop', 'show', 'shrink', 'shrug', 'sigh', 'sign', 'signal', 'sin',
    'sing', 'sip', 'ski', 'skip', 'slap', 'sleep', 'slice', 'slide', 'slip', 'slow',
    'smash', 'smell', 'smile', 'smoke', 'snatch', 'sneeze', 'sniff', 'snore', 'snow', 'soak',
    'solve', 'soothe', 'sound', 'spark', 'sparkle', 'speak', 'speed', 'spell', 'spend', 'spill',
    'spoil', 'spot', 'spray', 'sprout', 'squash', 'squeak', 'squeal', 'squeeze', 'stain', 'stamp',
    'stand', 'stare', 'start', 'stay', 'steer', 'step', 'stick', 'sting', 'stir', 'stop',
    'store', 'strap', 'strengthen', 'stretch', 'strike', 'strip', 'stroke', 'stuff', 'subtract', 'succeed',
    'suck', 'suffer', 'suggest', 'suit', 'supply', 'support', 'suppose', 'surprise', 'surround', 'suspect',
    'suspend', 'swear', 'sweat', 'sweep', 'swell', 'swim', 'swing', 'switch', 'talk', 'tame',
    'tap', 'taste', 'teach', 'tear', 'tease', 'telephone', 'tell', 'tempt', 'terrify', 'test',
    'thank', 'thaw', 'think', 'threaten', 'throw', 'tick', 'tickle', 'tie', 'time', 'tip',
    'tire', 'touch', 'tour', 'tow', 'trace', 'trade', 'train', 'transport', 'trap', 'travel',
    'treat', 'tremble', 'trick', 'trip', 'trot', 'trouble', 'trust', 'try', 'tug', 'tumble',
    'turn', 'twist', 'type', 'understand', 'unfasten', 'unite', 'unlock', 'untidy', 'use', 'vanish',
    'visit', 'wail', 'wait', 'walk', 'wander', 'want', 'warm', 'warn', 'wash', 'waste',
    'watch', 'water', 'wave', 'weigh', 'welcome', 'whine', 'whip', 'whirl', 'whisper', 'whistle',
    'wink', 'wipe', 'wish', 'wobble', 'wonder', 'work', 'worry', 'wrap', 'wreck', 'wrestle',
    'wriggle', 'xerox', 'yawn', 'yell', 'zip', 'zoom',
];
const adjectives = [
    'able', 'abnormal', 'above', 'absent', 'absolute', 'abstract', 'academic', 'acceptable', 'accessible', 'accurate',
    'active', 'actual', 'acute', 'addicted', 'adequate', 'admirable', 'adult', 'advanced', 'adventurous', 'affectionate',
    'afraid', 'aggressive', 'agreeable', 'alert', 'alive', 'all', 'alleged', 'amazing', 'ambitious', 'amused',
    'ancient', 'angry', 'annoyed', 'anxious', 'appalling', 'appropriate', 'approved', 'arctic', 'arrogant', 'artistic',
    'ashamed', 'asleep', 'athletic', 'attractive', 'authentic', 'average', 'awake', 'aware', 'awesome', 'awful',
    'bad', 'balanced', 'beautiful', 'beneficial', 'better', 'bewildered', 'big', 'bitter', 'black', 'blue',
    'blushing', 'bold', 'bored', 'boring', 'brainy', 'brave', 'brief', 'bright', 'brilliant', 'broken',
    'brown', 'bumpy', 'busy', 'calm', 'careful', 'careless', 'caring', 'cautious', 'charming', 'cheap',
    'cheerful', 'chilly', 'chubby', 'classy', 'clean', 'clear', 'clever', 'cloudy', 'clumsy', 'cold',
    'colorful', 'comfortable', 'common', 'complex', 'concerned', 'confident', 'confused', 'conscious', 'considerate', 'cool',
    'cooperative', 'courageous', 'crazy', 'creepy', 'critical', 'crowded', 'cruel', 'curious', 'curly', 'cute',
    'daily', 'damaged', 'damp', 'dangerous', 'daring', 'dark', 'dead', 'deaf', 'dear', 'decent',
    'deep', 'defeated', 'defiant', 'delicate', 'delicious', 'delightful', 'demanding', 'dense', 'dependable', 'depressed',
    'determined', 'different', 'difficult', 'digital', 'diligent', 'dirty', 'disappointed', 'distant', 'distinct', 'dizzy',
    'doubtful', 'drab', 'dramatic', 'dry', 'dull', 'eager', 'early', 'easy', 'educated', 'efficient',
    'elaborate', 'elastic', 'elated', 'elderly', 'elegant', 'embarrassed', 'eminent', 'empty', 'enchanted', 'encouraging',
    'energetic', 'enormous', 'enthusiastic', 'equal', 'essential', 'esteemed', 'ethical', 'euphoric', 'even', 'evil',
    'excellent', 'excited', 'exciting', 'expensive', 'experienced', 'expert', 'fabulous', 'fair', 'faithful', 'famous',
    'fancy', 'fantastic', 'fast', 'fat', 'faulty', 'fearful', 'fearless', 'feeble', 'fierce', 'filthy',
    'fine', 'fit', 'flaky', 'flat', 'flawless', 'flexible', 'fluffy', 'foolish', 'forgetful', 'fortunate',
    'fragile', 'frail', 'frantic', 'friendly', 'frightened', 'fun', 'funny', 'fussy', 'fuzzy', 'gallant',
    'generous', 'gentle', 'genuine', 'giant', 'gifted', 'gigantic', 'glamorous', 'gleaming', 'glistening', 'glorious',
    'good', 'gorgeous', 'graceful', 'gracious', 'grand', 'grateful', 'great', 'greedy', 'green', 'grieving',
    'grotesque', 'grumpy', 'guilty', 'gullible', 'hairy', 'handsome', 'happy', 'hard', 'harsh', 'hasty',
    'healthy', 'helpful', 'helpless', 'hilarious', 'hollow', 'honest', 'honorable', 'horrible', 'hot', 'huge',
    'humble', 'humorous', 'hungry', 'hurt', 'husky', 'icy', 'ideal', 'ill', 'illegal', 'imaginative',
    'immense', 'impartial', 'imperfect', 'important', 'impossible', 'incredible', 'indifferent', 'innocent', 'inquisitive', 'intelligent',
    'interesting', 'international', 'intrepid', 'irate', 'irritable', 'itchy', 'jaded', 'jealous', 'jittery', 'jolly',
    'joyful', 'joyous', 'jumpy', 'just', 'keen', 'kind', 'kindhearted', 'knowing', 'known', 'large',
    'late', 'lazy', 'legal', 'light', 'likeable', 'literate', 'little', 'lively', 'lonely', 'long',
    'loose', 'loud', 'lovely', 'loving', 'loyal', 'lucky', 'lumpy', 'lush', 'luxurious', 'mad',
    'magnificent', 'majestic', 'married', 'marvelous', 'massive', 'mature', 'mean', 'measly', 'meek', 'mellow',
    'melodic', 'melted', 'merciful', 'merry', 'messy', 'mighty', 'miniature', 'minor', 'miserable', 'modern',
    'modest', 'moist', 'mundane', 'muscular', 'mysterious', 'naive', 'narrow', 'nasty', 'natural', 'naughty',
    'nervous', 'new', 'nice', 'nimble', 'noisy', 'normal', 'nosy', 'nutritious', 'nutty', 'obedient',
    'obese', 'obnoxious', 'odd', 'old', 'orange', 'ordinary', 'organic', 'ornate', 'outgoing', 'outstanding',
    'overdue', 'overjoyed', 'painful', 'pale', 'perfect', 'perky', 'petite', 'phony', 'pink', 'plain',
    'pleasant', 'pleased', 'plucky', 'polite', 'poor', 'popular', 'powerful', 'precious', 'pretty', 'prickly',
    'proud', 'puzzled', 'quaint', 'quick', 'quiet', 'quirky', 'radiant', 'ragged', 'rainy', 'rapid',
    'rare', 'rash', 'raw', 'ready', 'real', 'reasonable', 'rebel', 'recent', 'reckless', 'red',
    'relieved', 'remarkable', 'repulsive', 'rich', 'rigid', 'ripe', 'roasted', 'robust', 'romantic', 'rotten',
    'rough', 'round', 'royal', 'rude', 'rundown', 'sad', 'safe', 'salty', 'same', 'sane',
    'sarcastic', 'satisfied', 'scared', 'scary', 'scattered', 'scientific', 'scrawny', 'secret', 'selfish', 'sensible',
    'serious', 'sharp', 'shiny', 'short', 'shy', 'silly', 'silver', 'skinny', 'sleepy', 'slight',
    'slimy', 'slippery', 'slow', 'small', 'smart', 'smiling', 'smoggy', 'smooth', 'soft', 'solid',
    'sore', 'sour', 'sparkling', 'special', 'speedy', 'spicy', 'splendid', 'spotless', 'square', 'stale',
    'standard', 'starry', 'steady', 'steep', 'sticky', 'stormy', 'strange', 'strict', 'striped', 'strong',
    'stunning', 'stupid', 'sturdy', 'substantial', 'successful', 'succulent', 'super', 'superb', 'superior', 'swanky',
    'sweet', 'tall', 'tame', 'tan', 'tart', 'tasty', 'teeny', 'tender', 'tense', 'terrible',
    'thankful', 'thin', 'thoughtful', 'thoughtless', 'thundering', 'tidy', 'tiny', 'tired', 'tough', 'tricky',
    'troubled', 'ugly', 'uneven', 'uninterested', 'unique', 'united', 'unkempt', 'unknown', 'unlucky', 'unusual',
    'upbeat', 'uplifting', 'upright', 'upset', 'uptight', 'vast', 'victorious', 'vigilant', 'vigorous', 'violent',
    'virtual', 'vivacious', 'vivid', 'wandering', 'warm', 'weary', 'weird', 'wet', 'whimsical', 'whispering',
    'white', 'wicked', 'wide', 'wiggly', 'wild', 'willing', 'witty', 'wobbly', 'wonderful', 'worried',
    'wrong', 'yawning', 'yellow', 'young', 'yummy', 'zany', 'zealous', 'zesty', 'zigzag',
];
const nouns = [
    'ability', 'absence', 'academy', 'access', 'accident', 'account', 'act', 'action', 'activity', 'actor',
    'addition', 'address', 'administration', 'advantage', 'advertisement', 'advice', 'affair', 'affection', 'age', 'agency',
    'agent', 'agreement', 'air', 'airplane', 'airport', 'alarm', 'alcohol', 'alert', 'alien', 'alliance',
    'amount', 'analysis', 'animal', 'answer', 'anxiety', 'apartment', 'appeal', 'apple', 'application', 'appointment',
    'area', 'argument', 'arm', 'army', 'art', 'article', 'artist', 'aspect', 'assignment', 'assistant',
    'associate', 'association', 'assumption', 'atmosphere', 'attack', 'attempt', 'attention', 'attitude', 'audience', 'author',
    'average', 'award', 'baby', 'background', 'balance', 'ball', 'band', 'bank', 'bar', 'base',
    'baseball', 'basis', 'basket', 'bath', 'battle', 'beach', 'bear', 'beauty', 'bed', 'bedroom',
    'beer', 'beginning', 'behavior', 'belief', 'bell', 'belt', 'bench', 'benefit', 'bicycle', 'bird',
    'birth', 'birthday', 'bit', 'bite', 'blood', 'board', 'boat', 'body', 'bone', 'book',
    'boot', 'border', 'bottle', 'bottom', 'bowl', 'box', 'boy', 'brain', 'branch', 'bread',
    'break', 'breakfast', 'breath', 'brick', 'bridge', 'brother', 'brush', 'bubble', 'budget', 'building',
    'bulb', 'bunch', 'burn', 'bus', 'business', 'button', 'cable', 'cake', 'calendar', 'camera',
    'camp', 'campaign', 'cancer', 'candidate', 'capital', 'car', 'card', 'care', 'career', 'carpet',
    'carrier', 'case', 'cash', 'cat', 'cause', 'celebration', 'cell', 'center', 'chain', 'chair',
    'challenge', 'chance', 'change', 'channel', 'chapter', 'charity', 'chart', 'check', 'cheek', 'chest',
    'chicken', 'child', 'chip', 'church', 'cigarette', 'city', 'class', 'clock', 'cloud', 'club',
    'coach', 'coal', 'coast', 'coat', 'code', 'coffee', 'cold', 'collection', 'college', 'color',
    'combination', 'comfort', 'command', 'comment', 'committee', 'company', 'comparison', 'competition', 'complaint', 'computer',
    'concept', 'concern', 'concert', 'condition', 'conference', 'confidence', 'conflict', 'connection', 'consequence', 'consideration',
    'construction', 'contact', 'contest', 'context', 'contract', 'control', 'conversation', 'cook', 'cookie', 'copy',
    'corner', 'cost', 'cotton', 'couch', 'council', 'country', 'county', 'couple', 'courage', 'course',
    'court', 'cousin', 'cover', 'cow', 'crack', 'craft', 'crash', 'cream', 'credit', 'crew',
    'criticism', 'crop', 'cross', 'crowd', 'crown', 'culture', 'cup', 'currency', 'current', 'curve',
    'customer', 'cut', 'cycle', 'dad', 'damage', 'dance', 'danger', 'dark', 'data', 'date',
    'daughter', 'day', 'deal', 'death', 'debate', 'debt', 'decision', 'deficit', 'degree', 'delay',
    'delivery', 'demand', 'department', 'departure', 'depth', 'description', 'design', 'desk', 'detail', 'development',
    'device', 'devil', 'diamond', 'diet', 'difference', 'difficulty', 'dinner', 'direction', 'director', 'dirt',
    'disaster', 'discipline', 'discount', 'discussion', 'disease', 'dish', 'disk', 'display', 'distance', 'distribution',
    'district', 'doctor', 'dog', 'door', 'dot', 'double', 'doubt', 'draft', 'drama', 'draw',
    'drawer', 'drawing', 'dream', 'dress', 'drink', 'drive', 'driver', 'drop', 'drug', 'drum',
    'duck', 'dust', 'duty', 'earth', 'economy', 'edge', 'editor', 'education', 'effect', 'effort',
    'egg', 'election', 'elevator', 'emotion', 'emphasis', 'employee', 'employer', 'energy', 'engine', 'engineer',
    'entertainment', 'enthusiasm', 'entrance', 'entry', 'environment', 'equipment', 'error', 'escape', 'essay', 'establishment',
    'estate', 'event', 'evidence', 'exam', 'examination', 'example', 'exchange', 'excitement', 'excuse', 'exercise',
    'exit', 'experience', 'expert', 'explanation', 'expression', 'extension', 'extent', 'eye', 'face', 'fact',
    'factor', 'failure', 'faith', 'fall', 'family', 'fan', 'farm', 'farmer', 'fat', 'father',
    'fault', 'fear', 'feature', 'fee', 'feedback', 'feeling', 'female', 'field', 'fight', 'figure',
    'file', 'film', 'filter', 'final', 'finance', 'finding', 'finger', 'finish', 'fire', 'fish',
    'flight', 'floor', 'flow', 'flower', 'fly', 'focus', 'food', 'foot', 'football', 'force',
    'forest', 'form', 'fortune', 'foundation', 'frame', 'freedom', 'friend', 'front', 'fruit', 'fuel',
    'fun', 'function', 'fund', 'funeral', 'future', 'gain', 'game', 'gap', 'garage', 'garden',
    'gas', 'gate', 'gather', 'gear', 'gene', 'general', 'gift', 'girl', 'girlfriend', 'glass',
    'goal', 'god', 'gold', 'golf', 'government', 'grab', 'grade', 'grain', 'grand', 'grandfather',
    'grandmother', 'grass', 'great', 'green', 'grocery', 'ground', 'group', 'growth', 'guest', 'guidance',
    'guide', 'guitar', 'habit', 'hair', 'half', 'hall', 'hand', 'handle', 'hang', 'harm',
    'hat', 'hate', 'head', 'health', 'hearing', 'heart', 'heat', 'heaven', 'heavy', 'height',
    'hell', 'hello', 'help', 'herb', 'hide', 'high', 'highlight', 'highway', 'hill', 'history',
    'hobby', 'hold', 'hole', 'holiday', 'home', 'homework', 'honey', 'hope', 'horror', 'horse',
    'hospital', 'host', 'hotel', 'hour', 'house', 'housing', 'human', 'husband', 'idea', 'image',
    'impact', 'implementation', 'importance', 'impression', 'improvement', 'incident', 'income', 'increase', 'independence', 'indication',
    'individual', 'industry', 'inflation', 'influence', 'information', 'initiative', 'injury', 'insect', 'inside', 'inspection',
    'instance', 'instruction', 'insurance', 'intention', 'interaction', 'interest', 'internet', 'interview', 'introduction', 'investment',
    'invite', 'involvement', 'island', 'issue', 'item', 'jacket', 'job', 'join', 'joint', 'joke',
    'journey', 'judge', 'judgment', 'juice', 'jump', 'junior', 'jury', 'keep', 'key', 'kind',
    'king', 'kitchen', 'knee', 'knife', 'knowledge', 'lab', 'lack', 'ladder', 'lady', 'lake',
    'land', 'landscape', 'language', 'laugh', 'law', 'lawyer', 'layer', 'lead', 'leader', 'leadership',
    'league', 'leather', 'leave', 'lecture', 'length', 'lesson', 'letter', 'level', 'library', 'lie',
    'life', 'lift', 'light', 'limit', 'line', 'link', 'list', 'literature', 'living', 'load',
    'loan', 'local', 'location', 'lock', 'log', 'long', 'look', 'loss', 'love', 'machine',
    'magazine', 'mail', 'main', 'maintenance', 'major', 'make', 'male', 'management', 'manager', 'manner',
    'manufacturer', 'map', 'march', 'mark', 'market', 'marketing', 'marriage', 'master', 'match', 'material',
    'math', 'matter', 'meal', 'meaning', 'measurement', 'meat', 'media', 'medicine', 'medium', 'meeting',
    'member', 'membership', 'memory', 'menu', 'mess', 'message', 'metal', 'method', 'middle', 'might',
    'milk', 'mind', 'mine', 'minimum', 'minor', 'minute', 'mirror', 'miss', 'mission', 'mistake',
    'mix', 'mixture', 'mobile', 'mode', 'model', 'mom', 'moment', 'money', 'monitor', 'month',
    'mood', 'morning', 'mortgage', 'mother', 'motor', 'mountain', 'mouse', 'mouth', 'move', 'movie',
    'mud', 'muscle', 'music', 'nail', 'name', 'nasty', 'nation', 'nature', 'negotiation', 'network',
    'news', 'night', 'noise', 'normal', 'north', 'note', 'nothing', 'notice', 'novel', 'number',
    'nurse', 'object', 'objective', 'obligation', 'occasion', 'offer', 'office', 'officer', 'official', 'oil',
    'opening', 'operation', 'opinion', 'opportunity', 'opposite', 'option', 'orange', 'order', 'organization', 'outcome',
    'outside', 'oven', 'owner', 'oxygen', 'package', 'page', 'pain', 'paint', 'painting', 'pair',
    'panel', 'paper', 'parent', 'park', 'parking', 'part', 'partner', 'party', 'pass', 'passage',
    'passenger', 'passion', 'past', 'path', 'patient', 'pattern', 'pause', 'payment', 'peace', 'peak',
    'pen', 'penalty', 'people', 'percent', 'perception', 'performance', 'period', 'permission', 'person', 'personality',
    'perspective', 'phase', 'phone', 'photo', 'phrase', 'physics', 'piano', 'picture', 'pie', 'piece',
    'pin', 'pipe', 'pitch', 'place', 'plan', 'plane', 'plant', 'platform', 'player', 'plenty',
    'poem', 'poet', 'poetry', 'point', 'policy', 'politics', 'pollution', 'pool', 'population', 'position',
    'possession', 'possibility', 'post', 'pot', 'potato', 'potential', 'pound', 'power', 'practice', 'preference',
    'preparation', 'presence', 'presentation', 'president', 'press', 'pressure', 'price', 'pride', 'priest', 'primary',
    'principle', 'printer', 'priority', 'prison', 'privacy', 'problem', 'procedure', 'process', 'produce', 'product',
    'profession', 'professional', 'profit', 'program', 'progress', 'project', 'promise', 'promotion', 'proof', 'property',
    'proposal', 'protection', 'psychology', 'public', 'pull', 'punch', 'purchase', 'purpose', 'push', 'put',
    'quality', 'quantity', 'queen', 'question', 'quit', 'quote', 'race', 'radio', 'rail', 'rain',
    'raise', 'range', 'rate', 'ratio', 'reach', 'reaction', 'reading', 'reality', 'reason', 'reception',
    'recipe', 'record', 'recording', 'recover', 'recruit', 'rectangle', 'reduction', 'reference', 'reflection', 'refrigerator',
    'refugee', 'region', 'register', 'regret', 'relation', 'relationship', 'relief', 'religion', 'replacement', 'reply',
    'report', 'republic', 'reputation', 'request', 'research', 'reserve', 'resident', 'resist', 'resolution', 'resource',
    'response', 'responsibility', 'restaurant', 'result', 'return', 'revenue', 'review', 'revolution', 'reward', 'rice',
    'rich', 'ride', 'ring', 'river', 'road', 'rock', 'role', 'roof', 'room', 'rope',
    'round', 'routine', 'row', 'royal', 'rule', 'run', 'safety', 'sail', 'salad', 'salary',
    'sale', 'sample', 'sand', 'satisfaction', 'sauce', 'saving', 'scale', 'scene', 'schedule', 'scheme',
    'school', 'science', 'score', 'scratch', 'screen', 'search', 'season', 'seat', 'secret', 'secretary',
    'section', 'sector', 'security', 'selection', 'self', 'senior', 'sense', 'series', 'service', 'session',
    'setting', 'shade', 'shadow', 'shake', 'shape', 'share', 'sheep', 'sheet', 'shelf', 'shell',
    'shift', 'shine', 'ship', 'shirt', 'shock', 'shoe', 'shoot', 'shop', 'shopping', 'shot',
    'shoulder', 'show', 'sick', 'side', 'sign', 'signal', 'signature', 'significance', 'singer', 'sink',
    'sir', 'sister', 'site', 'situation', 'size', 'skill', 'skin', 'sky', 'sleep', 'slice',
    'slide', 'smile', 'smoke', 'snow', 'society', 'sock', 'software', 'soil', 'solution', 'son',
    'song', 'sort', 'sound', 'soup', 'source', 'south', 'space', 'speaker', 'specialist', 'speech',
    'speed', 'spend', 'spirit', 'sport', 'spot', 'spray', 'spread', 'spring', 'square', 'stable',
    'staff', 'stage', 'stand', 'standard', 'star', 'start', 'state', 'statement', 'station', 'status',
    'steak', 'step', 'stock', 'stomach', 'stop', 'storage', 'store', 'storm', 'story', 'strain',
    'strategy', 'street', 'strength', 'structure', 'student', 'studio', 'study', 'stuff', 'stupid', 'style',
    'subject', 'success', 'sugar', 'suggestion', 'summer', 'sun', 'supermarket', 'support', 'surface', 'surgery',
    'surprise', 'survey', 'swing', 'switch', 'system', 'table', 'tackle', 'tale', 'talk', 'tank',
    'tape', 'target', 'task', 'taste', 'tax', 'tea', 'teacher', 'team', 'tear', 'technology',
    'telephone', 'television', 'temperature', 'temporary', 'tennis', 'tension', 'term', 'test', 'text', 'thanks',
    'theme', 'theory', 'thing', 'thought', 'thread', 'throat', 'ticket', 'tie', 'time', 'tip',
    'title', 'today', 'toe', 'tomorrow', 'tone', 'tongue', 'tool', 'tooth', 'topic', 'total',
    'touch', 'tour', 'towel', 'tower', 'town', 'track', 'trade', 'traffic', 'train', 'trainer',
    'training', 'transition', 'transport', 'trash', 'travel', 'treat', 'tree', 'trick', 'trip', 'trouble',
    'truck', 'trust', 'truth', 'try', 'tune', 'turn', 'twist', 'type', 'uncle', 'understanding',
    'union', 'unit', 'university', 'upper', 'use', 'user', 'usual', 'vacation', 'valley', 'value',
    'variation', 'variety', 'vehicle', 'version', 'video', 'view', 'village', 'virus', 'visit', 'visual',
    'voice', 'volume', 'wait', 'walk', 'wall', 'war', 'warning', 'wash', 'watch', 'water',
    'wave', 'way', 'wealth', 'weather', 'web', 'wedding', 'week', 'weekend', 'weight', 'welcome',
    'wheel', 'while', 'white', 'wife', 'will', 'wind', 'window', 'wine', 'wing', 'winner',
    'winter', 'wish', 'woman', 'wood', 'word', 'work', 'worker', 'world', 'worry', 'writer',
    'writing', 'yard', 'year', 'youth', 'zone',
];
// Function to generate a random number between min and max (inclusive)
function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
async function getRandomPassword() {
    try {
        const response = await fetch('https://random-word-api.herokuapp.com/word?number=3');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const words = await response.json(); // This will be an array of 3 words
        const password = words.join('-'); // Concatenate words with hyphens
        return password;
    }
    catch (error) {
        console.error('Error fetching random words:', error);
        // Fallback: Generate password from local arrays
        const randomVerb = verbs[Math.floor(Math.random() * verbs.length)];
        const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
        const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
        const randomNumber = getRandomNumber(1, 100); // Number between 1 and 100
        const password = `${randomVerb}-${randomAdjective}-${randomNoun}-${randomNumber}`;
        return password;
    }
}
