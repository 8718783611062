"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_svg_1 = __importStar(require("react-native-svg"));
const IconOnSteroid = ({ icon, gradientColors = ['#74c9e3', '#0061a7'], x1, x2, y1, y2, ...restProps }) => {
    const id = (0, react_1.useId)();
    const width = (0, react_1.useMemo)(() => {
        if ('size' in restProps) {
            return restProps.size ?? 24;
        }
        ;
        if ('width' in restProps) {
            return restProps.width ?? restProps.height ?? 24;
        }
        ;
        return 24;
    }, [restProps]);
    const height = (0, react_1.useMemo)(() => {
        if ('size' in restProps) {
            return restProps.size ?? 24;
        }
        ;
        if ('height' in restProps) {
            return restProps.height ?? restProps.width ?? 24;
        }
        ;
        return 24;
    }, [restProps]);
    const hasCustomGradientAngle = (0, react_1.useMemo)(() => ((x1 ?? x2 ?? y1 ?? y2) !== undefined), [x1, x2, y1, y2]);
    const { icon: [iconWidth, iconHeight, , , svgPathData] } = icon;
    const pathData = Array.isArray(svgPathData) ? svgPathData.join(' ') : svgPathData;
    // Choose a scaling factor.  
    // For example, 10x. Experiment with different values.
    const scaleFactor = 10;
    const scaledWidth = iconWidth * scaleFactor;
    const scaledHeight = iconHeight * scaleFactor;
    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: { width, height }, children: (0, jsx_runtime_1.jsxs)(react_native_svg_1.default, { width: width, height: height, viewBox: `0 0 ${scaledWidth} ${scaledHeight}`, children: [(0, jsx_runtime_1.jsxs)(react_native_svg_1.Defs, { children: [(0, jsx_runtime_1.jsxs)(react_native_svg_1.LinearGradient, { id: `${id}-grad`, x1: x1 ?? 0, y1: y1 ?? 0, x2: x2 ?? hasCustomGradientAngle ? 0 : 1, y2: y2 ?? 0, children: [(0, jsx_runtime_1.jsx)(react_native_svg_1.Stop, { offset: "0", stopColor: gradientColors[0], stopOpacity: "1" }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Stop, { offset: "1", stopColor: gradientColors[1], stopOpacity: "1" })] }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Mask, { id: `${id}-mask`, children: (0, jsx_runtime_1.jsx)(react_native_svg_1.G, { transform: `scale(${scaleFactor})`, children: (0, jsx_runtime_1.jsx)(react_native_svg_1.Path, { d: pathData, fill: "white" }) }) })] }), (0, jsx_runtime_1.jsx)(react_native_svg_1.Rect, { x: 0, y: 0, width: scaledWidth, height: scaledHeight, fill: `url(#${id}-grad)`, mask: `url(#${id}-mask)` })] }) }));
};
exports.default = IconOnSteroid;
