"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkout = exports.getPlans = void 0;
const axios_1 = require("axios");
const interceptorsAnon_1 = require("./interceptorsAnon");
const apis_1 = require("../common/apis");
const error_1 = require("../common/error");
const interceptorsCached_1 = require("./interceptorsCached");
/**
 * Fetches available pricing plans
 * @returns Promise resolving to an APIResponse containing array of PricingPlan or a RequestError
 */
const getPlans = async () => {
    return interceptorsAnon_1.cachedNetworkCall
        .get(apis_1.APP_CONSTANTS.purchases.plans)
        .then((response) => {
        if (!response.success || response.error) {
            return new error_1.APIError(response.error?.message);
        }
        return response.data;
    })
        .catch((error) => {
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized
                ? 'Session Out' : undefined
        });
    });
};
exports.getPlans = getPlans;
/**
 * Initiates checkout process for a selected plan
 * @param payload - Object containing stripePlanId
 * @returns Promise resolving to an APIResponse containing checkout URL or a RequestError
 */
const checkout = async (payload) => {
    return interceptorsCached_1.cachedAuthCall
        .post(apis_1.APP_CONSTANTS.purchases.checkout, { stripePlanId: payload })
        .then((response) => {
        if (!response.success || response.error) {
            return new error_1.APIError(response.error?.message);
        }
        return response.data;
    })
        .catch((error) => {
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized
                ? 'Session Out' : undefined
        });
    });
};
exports.checkout = checkout;
