"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CloseButton;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const colors_1 = require("../../../common/colors");
function CloseButton({ style, ...restProps }) {
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { style: (pressed) => [styles.button, typeof style === 'function' ? style(pressed) : style], ...restProps, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faTimes, color: colors_1.Colors.white, size: 14 }) }));
}
;
const styles = react_native_1.StyleSheet.create({
    button: {
        backgroundColor: 'rgba(62, 62, 66, 0.50)',
        padding: 9,
        borderRadius: 56,
    },
});
