"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ChatImage;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
function ChatImage({ source, thumbnail, onClose }) {
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { children: [(0, jsx_runtime_1.jsx)(react_native_1.Image, { source: { uri: source }, resizeMode: 'stretch', style: [styles.image, thumbnail && styles.thumbnail] }), onClose && ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: onClose, style: styles.closeButton, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes, color: '#121218', size: 16 }) }))] }));
}
;
const styles = react_native_1.StyleSheet.create({
    image: {
        width: 120,
        height: 120,
        borderRadius: 8,
    },
    thumbnail: {
        width: 72,
        height: 72,
    },
    closeButton: {
        backgroundColor: '#FEFEFF',
        position: 'absolute',
        top: 4,
        right: 3.5,
        padding: 2,
        borderRadius: 38,
    },
});
