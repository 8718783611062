"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextAreaForm = TextAreaForm;
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_1 = require("react");
const colors_1 = require("../../common/colors");
function TextAreaForm({ id, placeholder, value, defaultValue, onChangeText, heigth, ...restProps }) {
    const defaultStyle = (0, react_1.useMemo)(() => {
        return {
            backgroundColor: colors_1.Colors.DARK_700,
            color: value || defaultValue ? colors_1.Colors.white : colors_1.Colors.DARK_300,
            padding: 20,
        };
    }, [value]);
    // Style for rightElement & Left element stack
    const defaultStackStyle = (0, react_1.useMemo)(() => {
        return {
            bg: colors_1.Colors.DARK_700,
        };
    }, [value]);
    const defaultFocusStyle = (0, react_1.useMemo)(() => {
        return {
            style: {
                color: value ? colors_1.Colors.white : colors_1.Colors.DARK_300,
                backgroundColor: colors_1.Colors.PRIMARY_700,
            },
            selectionColor: colors_1.Colors.white,
            placeholderTextColor: colors_1.Colors.white, //value ? Colors.white : Colors.DARK_300,
            _stack: {
                background: colors_1.Colors.PRIMARY_700,
            },
        };
    }, [value]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(native_base_1.TextArea, { style: { ...defaultStyle }, defaultValue: defaultValue, h: heigth || 20, placeholder: placeholder, w: "100%", 
            // py={5}
            value: value, borderRadius: 8, placeholderTextColor: colors_1.Colors.DARK_300, borderColor: colors_1.Colors.DARK_500, variant: "rounded", focusOutlineColor: colors_1.Colors.DARK_500, 
            // selectionColor={Colors.DARK_200}
            // bg={bgColor}
            // color={color}
            fontSize: 16, onChangeText: (value) => onChangeText(value, id), autoCompleteType: undefined, _focus: defaultFocusStyle, _stack: defaultStackStyle, _hover: {
                borderColor: colors_1.Colors.PRIMARY_100,
            }, ...restProps }) }));
}
